const ossUrl = 'https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/reirc_operate/'
const env = process.env.APP_ENV
const defaultHead = 'https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_web/login/default.jpg'

let user, userKey = 'reirc-operate-user'
const temp = localStorage.getItem(userKey)
if (temp) {
  user = JSON.parse(temp)
}
function setUser(value) {
  user = value
  localStorage.setItem(userKey, JSON.stringify(value))
}

function replaceItemAtIndex(arr, index, newValue) {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)]
}

function removeItemAtIndex(arr, index) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)]
}

// filter
function cityChar(array) {
  return array.map(item => item.name).join('、')
}
function cityStr(str) {
  return str.split(',').join('、')
}
const salaryUnit = ['', '元·天', 'K·月', 'W·年']
function salaryChar(obj) {
  if (obj.salaryType === 0) return '面议'
  return `${obj.salaryMin}-${obj.salaryMax}${salaryUnit[obj.salaryType]}`
}

// 组织位置
const epLocationList = [
  {label: '总部', value: '总部'},
  {label: '区域/分子公司', value: '区域/分子公司'},
  {label: '项目公司', value: '项目公司'},
]

//图表
function loadECharts() {
  if (!window.echarts) {
      const script = document.createElement('script')
      script.src =
          'https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/echarts.min.js'
      script.async = true
      document.head.appendChild(script)
  }
}

// 周中文
const weekArray = ['', '一', '二', '三', '四', '五', '六', '日']
function week(index) {
  return weekArray[index]
}

export {
  ossUrl,
  env,
  user,
  defaultHead,
  setUser,
  replaceItemAtIndex,
  removeItemAtIndex,
  cityChar,
  cityStr,
  salaryChar,
  epLocationList,
  loadECharts,
  week,
}