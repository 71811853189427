import Breadcrumb from '../common/Breadcrumb'
import {Link} from 'react-router-dom'
import {useFastReducer} from '../components/hooks'
import {useEffect} from 'react'
import ajax from '../ajax/operateData'
import commonAjax from '../ajax/common'
import {Select, Pagination, DatePicker} from 'antd'
import DataGap from './searchPanel/DataGap'
import {user, epLocationList} from '../components'
import ChooseAb from '../components/form/ChooseAb'
import './operateList.scss'
import PosListModal from './PosListModal'
import Dayjs from 'dayjs'
import TableCanvas from './TableCanvas'
import toast from '../components/toast'

const pageSize = 20
let dataCache, params, stateCache
const cache = sessionStorage.getItem('operate-data')

if (cache) {
  stateCache = JSON.parse(cache)
  stateCache.date = Dayjs(stateCache.date)
  stateCache.dateRange = stateCache.dateRange.map(item => item ? Dayjs(item) : null)
}

const {RangePicker} = DatePicker
const dataGap = {
  startPosCount: '', endPosCount: '',
  startReadCount: '', endReadCount: '',
  startAddFansCount: '', endAddFansCount: '',
  startAddCount: '', endAddCount: '',
  startTransformCount: '', endTransformCount: '',
}
const dateType = ['', 'date', 'week', 'month', 'quarter', 'year']

export default function OperateList() {
  const [state, dispatch] = useFastReducer(stateCache ? stateCache : {
    tab: 1, // 个人 团队
    type: 1, // 日周月季年
    userId: null,
    userList: [],
    isManager: false,
    keyword: '',
    searchContent: '',
    isCompare: false,
    isMaker: false, // 是否显示内容制作人

    expand: true,
    dateRange: [null, null],
    date: null,
    cate: null,
    cateList: [{label: '私营', value: '私营'}, {label: '国企', value: '国企'}],
    location: null,
    epLocationList,
    ability: '',
    abilityId: '',
    abilityList: [],
    level: null,
    levelList: [],
    dataGap,
    cityIndex: -1,
    cityList: [],

    list: null,
    page: 1,
    total: 0,

    b: null, // B端开拓弹窗
    isPosModal: null,
    hr: null,

    isTable: '', // 导出运营数据
  })

  const city = state.cityIndex > -1 ? state.cityList[state.cityIndex] : null

  // 个人查看日数据，先拉取城市列表
  function getCityByUser(userId) {
    ajax.getCityByUser(userId).then(res => {
      if (res.success && res.data.length > 0) {
        const cityList = res.data
        let cityIndex = state.cityIndex
        if (city) {
          cityIndex = cityList.findIndex(item => item.id === city.id)
        }
        cityIndex = cityIndex === -1 ? 0 : cityIndex
        dispatch({userId, cityIndex, cityList,})
      }
    })
  }

  useEffect(function () {
    if (user) {
      const isManager = Boolean(user.isManage)
      dispatch({isManager})
      if (isManager) {
        ajax.getUserList().then(res => {
          if (res.success && res.data.length > 0) {
            const userList = res.data.map(it => ({label: it.username, value: it.id}))
            dispatch({userList})
            if (!state.userId || (state.userId && (userList.find(user => user.value === state.userId) === undefined))) {
              // 没有userid或者userid在数组中找不到了，默认取数组第一个
              getCityByUser(userList[0].value)
            } else {
              // 有userid查对应的
              getCityByUser(state.userId)
            }
          }
        })
      } else {
        getCityByUser(user.id)
      }
    }

    // 拉取下拉框数据
    if (dataCache) {
      dispatch({
        abilityList: dataCache[1],
        levelList: dataCache[2],
      })
    } else {
      commonAjax.getBusinessCate().then(res => {
        if (res.success) {
          dataCache = res.data
          dataCache[2] = dataCache[2].map(it => ({label: it.name, value: it.id}))
          dataCache[3] = dataCache[3].map(it => ({label: it.name, value: it.id}))
          dispatch({
            abilityList: dataCache[1],
            levelList: dataCache[2],
          })
        }
      })
    }
  }, [])

  useEffect(function () {
    // 个人菜单下必须要有用户ID和城市id才执行查询
    if (state.tab === 1 && (!state.userId || !city)) {
      return
    }
    search()
  }, [state.tab, state.userId, state.cityIndex, state.type, state.isCompare])

  function handleKeyword(e) {
    const value = e.target.value.trim()
    dispatch({keyword: value})
  }
  function enterToSearch(e) {
    if (e.key === 'Enter') {
      search()
    }
  }
  function delToSearch() {
    dispatch({keyword: ''})
    if (params.searchContent) {
      search(undefined, undefined, true)
    }
  }
  // 团队下面 日期变更
  function dateChange(date) {
    dispatch({date})
    search(false, 1, false, date)
  }
  function search(reset, inputPage, clearKeyword, date) {
    const page = inputPage || 1 // 没传page就请求第一页
    // 清空数组并设置页码
    dispatch({page})
    params = {
      page,
      size: pageSize,
      userId: state.userId,
      cityId: city ? city.id : null,
      timeType: state.type,
      compare: Number(state.isCompare),
    }
    if (state.tab === 2) { // 团队去除分页，人，城市
      params.isTeam = 1
      delete params.userId
      delete params.cityId
    }
    if (reset) {
      if (state.tab === 2) {
        const temp = Dayjs().subtract(1, 'day').format('YYYY-MM-DD')
        params.startTime = temp
        params.endTime = temp
      }
    } else { // 不是重置，就要读取每个筛选项
      if (!clearKeyword && state.keyword) {
        params.searchContent = state.keyword
      }
      let startTime, endTime
      if (state.tab === 1) { // 个人是日期范围
        [startTime, endTime] = state.dateRange
      }
      if (state.tab === 2) { // 团队是单个日期
        if (date) {
          startTime = endTime = date
        } else {
          startTime = endTime = state.date
        }
      }
      if (startTime && endTime) {
        if (state.type > 1) {
          const type = dateType[state.type]
          startTime = startTime.startOf(type)
          endTime = endTime.endOf(type)
        }
        params.startTime = startTime.format('YYYY-MM-DD')
        params.endTime = endTime.format('YYYY-MM-DD')
      }
      // 日颗粒度读企业和职能筛选
      if (state.type === 1) {
        if (state.cate) {
          params.property = state.cate
        }
        if (state.location) {
          params.organization = state.location
        }
        if (state.ability) {
          params.businessId = state.abilityId
        }
        if (state.level) {
          params.category = state.level
        }
      } else {
        // 周颗粒度去除分页，城市
        delete params.page
        delete params.size
        delete params.cityId
      }
      // 数据区间有值就set
      for (let key in state.dataGap) {
        if (state.dataGap.hasOwnProperty(key)) {
          const value = state.dataGap[key]
          if (value !== '') {
            params[key] = Number(value)
          }
        }
      }
    }
    ajax.getList(state.type, params).then(res => {
      if (res.success && res.data) {
        if (state.type === 1) {
          dispatch({list: res.data.records, total: res.data.total})
        } else {
          dispatch({list: res.data})
        }
      } else {
        dispatch({list: null})
      }
    })
  }

  function reset() {
    dispatch({
      keyword: '',
      dateRange: [null, null],
      date: Dayjs().subtract(1, 'day'),
      cate: null,
      location: null,
      ability: null,
      level: null,
      dataGap: {...dataGap},
    })
    search(true)
  }
  function tabChange(tab) {
    dispatch({
      tab,
      type: 1,
      isCompare: false,
      isMaker: false,
      keyword: '',
      dateRange: [null, null],
      date: Dayjs().subtract(1, 'day'),
      cate: null,
      location: null,
      ability: null,
      level: null,
      dataGap: {...dataGap},
      list: null,
      total: 0,
    })
  }
  // 显示导出运营数据的弹窗
  function showTable() {
    dispatch({isTable: state.date.format('YYYY-MM-DD')})
  }
  stateCache = state
  // 持久缓存运营数据路由下的state
  const storeCache = {
    ...state,
    date: state.date ? state.date.format('YYYY-MM-DD') : '',
    dateRange: state.dateRange.map(item => item ? item.format('YYYY-MM-DD') : null)
  }
  sessionStorage.setItem('operate-data', JSON.stringify(storeCache))

  return (
    <div className="com-content operate-list">
      <div className="operate-fix-wrap">
        <Breadcrumb path="/operate-data/list" />
        {/*tab栏*/}
        <div className="flex between align">
          <div className="tab-wrap">
           <div className={`tab ${state.tab === 1}`} onClick={() => tabChange(1)}>个人</div>
           <div className={`tab ${state.tab === 2}`} onClick={() => tabChange(2)}>团队</div>
          </div>
          {(state.tab === 1 && city) && (
            <Link to={`../form?city=${city.cityName}&cityId=${city.id}&cityDictId=${city.cityId}&userId=${state.userId}`} className="plus-button">新建运营数据</Link>
          )}
          {state.tab === 2 && (
            <div className="plus-button no-icon" onClick={showTable}>
              <div className="download-icon"></div>
              <div>导出运营数据</div>
            </div>
          )}
        </div>
        {/*搜索栏*/}
        <div className="search-section flex align between">
          <div className="search-wrap">
            <input
              type="text"
              className="search-input"
              placeholder="可通过企业、职位、姓名、城市查询"
              value={state.keyword}
              onChange={handleKeyword}
              onKeyDown={enterToSearch}
            />
            <div className={`del-wrap ${Boolean(state.keyword)}`} onClick={delToSearch}>
              <div className="del-icon"></div>
            </div>
            <div className="search-icon" onClick={() => search()}></div>
          </div>
          <div className="filter-wrap f-12">
            {(state.tab === 1 && state.isManager) && (
              <Select value={state.userId} options={state.userList} onChange={v => getCityByUser(v)} />
            )}
            <div className="type-group">
              <div className={`type ${state.type === 1}`} onClick={() => dispatch({type: 1})}>日</div>
              <div className={`type ${state.type === 2}`} onClick={() => dispatch({type: 2})}>周</div>
              <div className={`type ${state.type === 3}`} onClick={() => dispatch({type: 3})}>月</div>
              {(state.tab === 2 && !state.isManager) ? null : (
                <>
                  <div className={`type ${state.type === 4}`} onClick={() => dispatch({type: 4})}>季</div>
                  <div className={`type ${state.type === 5}`} onClick={() => dispatch({type: 5})}>年</div>
                </>
              )}
            </div>
            <div className="check-group hand" onClick={() => dispatch({isCompare: !state.isCompare})}>
              <div className={`com-check-solid ${state.isCompare}`}></div>
              <div>同比对比</div>
            </div>
            {state.tab === 1 && (
              <div className="check-group hand" onClick={() => dispatch({isMaker: !state.isMaker})}>
                <div className={`com-check-solid small ${state.isMaker}`}></div>
                <div>内容制作人</div>
              </div>
            )}
            <div className="filter-btn f-12 f-600 hand" onClick={() => dispatch({expand: !state.expand})}>筛选</div>
          </div>
        </div>
        {/*搜索面板*/}
        <div className={`search-panel ${state.expand}`}>
          <div className="first-wrap f-12">
            {(state.tab === 2 && !state.isManager) ? null : (
              <div className="time-wrap flex align">
                <div className="f-600 mr-10">时间选择</div>
                {state.tab === 1 ? <RangePicker picker={dateType[state.type]} value={state.dateRange} onChange={d => dispatch({dateRange: d})} />
                  : <DatePicker picker={dateType[state.type]} value={state.date} onChange={d => dateChange(d)} />}
              </div>
            )}
            {state.type === 1 && (
              <div className="ep-wrap flex align">
                <div className="blue1">企业筛选</div>
                <Select placeholder="企业性质" value={state.cate} options={state.cateList} onChange={v => dispatch({cate: v})} />
                <div className="mr-10"></div>
                <Select placeholder="组织位置" value={state.location} options={state.epLocationList} onChange={v => dispatch({location: v})} />
                <div className="blue1 pl-20">职能筛选</div>
                <div className="ab-wrap">
                  <ChooseAb placeholder="职能" value={state.ability} onChange={v => dispatch({ability: v.name, abilityId: v.id})} />
                </div>
                <Select placeholder="职级" value={state.level} options={state.levelList} onChange={v => dispatch({level: v})} />
              </div>
            )}
          </div>
          <div className="flex between second-wrap">
            <DataGap state={state.dataGap} onChange={dispatch} />
            <div className="flex align f-12">
              <div className="act search hand" onClick={() => search()}>搜索</div>
              <div className="act reset hand" onClick={reset}>重置</div>
              <div className="tog-act hand" onClick={() => dispatch({expand: false})}>收起</div>
            </div>
          </div>
        </div>

        {/*城市列表*/}
        <div className={`city-panel ${state.type === 1 && state.tab === 1}`}>
          {state.cityList.map((item, i) => (
            <div key={item.id} className={`city ${state.cityIndex === i}`} onClick={() => dispatch({cityIndex: i})}>{item.cityName}</div>
          ))}
        </div>

        {state.type === 1 && <DayTable state={state} handlePage={v => search(false, v)} showB={b => dispatch({b})} showPos={id => dispatch({isPosModal: id})} showHr={hr => dispatch({hr})} />}
        {state.type > 1 && <MonthTable state={state} showB={b => dispatch({b})} />}

        {/*B端开拓*/}
        {state.b && <BModal data={state.b} close={() => dispatch({b: null})} />}
        {/*职位列表弹窗*/}
        {state.isPosModal && <PosListModal close={() => dispatch({isPosModal: null})} type="order" id={state.isPosModal} />}
        {/*HR弹窗*/}
        {state.hr && <HRModal hr={state.hr} />}
        {/*运营数据弹窗*/}
        {state.isTable && <DataTable date={state.isTable} close={() => dispatch({isTable: ''})} />}
      </div>
    </div>
  )
}

// 日表格
function DayTable({state, handlePage, showB, showPos, showHr}) {
  if (!state.list) {
    return null
  }
  if (state.list.length === 0) {
    return <div className="empty-msg">暂无数据</div>
  }
  return (
    <div className="operate-table-wrap">
      <table className="operate-table">
        <thead>
          <tr className="table-head">
            <th className="date">日期</th>
            <th className="brand">品牌</th>
            {state.tab === 2 && <th>城市/运营</th>}
            <th className="">职位数</th>
            <th className="">阅览量</th>
            <th className="">公号总粉/新增</th>
            <th className="">微信/好友新增</th>
            <th className="">60%成本线 · 当日付费</th>
            {state.tab === 2 && <th>剩余工作日<br/>日均目标</th>}
            {state.tab === 2 && <th>完成/城市目标/比例</th>}
            <th className="">B端开拓</th>
            <th className="">朋友圈扩散</th>
            <th className="">自建/外部/HR</th>
            <th className="direct">HR转发扩散</th>
            {state.tab === 1 && state.type === 1 && <th>操作</th>}
          </tr>
        </thead>
        <tbody>
          {state.list.map((item, i) => (
          <tr key={i} className={`table-item ${state.isCompare}`}>
            <td className="blue-bg">
              <div className="f-600">{item.publishTime}</div>
              {state.isMaker && <div className="c-9">{item.createUser}</div>}
            </td>
            <td className="">
              {item.brand ? (
                <div
                  className="link f-600"
                  onMouseOver={() => showHr(item)}
                  onMouseLeave={() => showHr(null)}
                >{item.brand}</div>
              ) : '--'}
            </td>
            {state.tab === 2 && <td>{item.city || '-'}/{item.username || '-'}</td>}
            <td className="" >
              {item.posCount > 0 ? <div className="link underline f-600" onClick={() => showPos(item.id)}>{item.posCount}</div> : 0}
            </td>
            <td>{item.readCount}</td>
            <td>
              <span className="f-600">{item.fansCount}/</span>
              <span className="red f-600">+{item.addCount}</span>
            </td>
            <td>
              <span className="f-600">{item.wxFriendCount}/</span>
              <span className="red f-600">+{item.wxAddCount}</span>
            </td>
            <td>
              {item.cost} · <span className="cost">{item.wxTransformCount}</span>
            </td>
            {state.tab === 2 && <td>{item.residueDayTarget}</td>}
            {state.tab === 2 && (
              <td className="f-600">
                <span className="green">{item.completeCount}</span>
                <span>/{item.cityTarget}</span>
                <span className="c-9"> · {item.percent || '-'}</span>
              </td>
            )}
            <td>
              {(item.hrCount + item.enterpriseCount + item.addHrCommunityCount) > 0 ? (
                <div className="link underline f-600" onClick={() => showB(item)}>是</div>
              ) : <div className="c-9">否</div>}
            </td>
            <td>{item.friendCount}</td>
            <td>{item.selfCommunityCount}/{item.externalCommunityCount}/{item.hrCommunityCount}</td>
            <td className="direct blue2">{item.hrRedirect || '--'}</td>
            {state.tab === 1 && state.type === 1 && (
              <td>
                <Link to={`../form/${item.id}`} className="edit-btn">
                  <div className="edit-icon"></div>
                  编辑
                </Link>
              </td>
            )}
          </tr>
        ))}
        </tbody>
      </table>
      <div className="flex page-wrap">
        <Pagination current={state.page} total={state.total} pageSize={pageSize} onChange={handlePage} showSizeChanger={false} />
      </div>
    </div>
  )
}
// 月表格
function MonthTable({state, showB}) {
  if (!state.list) {
    return null
  }
  if (state.list.length === 0) {
    return <div className="empty-msg">暂无数据</div>
  }
  return (
    <table className="operate-table">
      <thead>
        <tr className="table-head">
          <th className="date">日期</th>
          {state.tab === 2 && <th>城市/运营</th>}
          <th className="">推文企业</th>
          <th className="">去重发布</th>
          <th className="">国企/私营</th>
          <th className="">日均/职位总量</th>
          <th className="">目标数/达成比</th>
          <th className="black f-600">日均/总转化</th>
          <th className="">团队排名</th>
          <th className="">日均阅览量</th>
          <th className="">日均/总添加</th>
          <th className="">日均/总增粉</th>
          <th className="">B端开拓</th>
        </tr>
      </thead>
      <tbody>
        {state.list.map((item, i) => (
          <tr key={i} className="table-item">
            <td className="blue-bg">
              <div className="f-600">{item.date}</div>
            </td>
            {state.tab === 2 && <td>{item.city || '-'}/{item.username || '-'}</td>}
            <td>{item.publishEnterpriseCount}</td>
            <td>{item.distinctCount}</td>
            <td>{item.soeCount}/{item.peCount}</td>
            <td>{item.dayPosCount}/{item.posTotalCount}</td>
            <td className="f-600">
              <span>{item.target}/</span>
              <span className="green">{item.completePercent || '-'}</span>
            </td>
            <td>
              <div className="red-badge f-600">
                <span className="red">{item.dayTransFormCount}/</span>
                <span>{item.totalTransFormCount}</span>
              </div>
            </td>
            <td className="f-600">{item.rank}</td>
            <td className="f-600">{item.dayReadCount}</td>
            <td className="f-600">
              <span className="red">{item.dayAddFriendCount}</span>
              <span>/{item.totalAddFriendCount}</span>
            </td>
            <td className="f-600">
              <span className="red">{item.dayAddCount}</span>
              <span>/{item.totalAddCount}</span>
            </td>
            <td>
              {(item.hrCount + item.enterpriseCount + item.addHrCommunityCount) > 0 ? (
                <div className="link underline f-600" onClick={() => showB(item)}>是</div>
              ) : <div className="c-9">否</div>}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
// B端开拓
function BModal({data, close}) {
  return (
    <div className="com-modal b-modal-form">
      <div className="com-modal-wrap">
        <div className="modal-head">
          <div className="modal-title">B端开拓</div>
          <div className="close-wrap" onClick={close}>
            <div className="close-icon"></div>
          </div>
        </div>
        <div className="modal-content">
          <div className="b-modal flex f-500 f-12">
            <div className="item">新增HR好友：{data.hrCount}</div>
            <div className="item">新增HR社群：{data.addHrCommunityCount}</div>
            <div className="item">新增发布企业：{data.enterpriseCount}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
// HR 弹窗
function HRModal({hr}) {
  return (
    <div className="com-hover-modal">
      <div className="head-wrap">HR信息 | {} {hr.enterpriseName}</div>
      <div className="hr-wrap">
        <div className="hr flex">
          <div className="group f-500">{hr.name} | {hr.job}</div>
          <div className="group">
            <span className="c-75">直属领导职务：</span>
            <span className="f-500">{hr.leaderJob}</span>
          </div>
          <div className="group">
            <span className="c-75">Tel：</span>
            <span className="f-500">{hr.phone}</span>
          </div>
          <div className="">
            <span className="c-75">Email：</span>
            <span className="f-500">{hr.email}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
// 导出运营数据
function DataTable({date, close}) {
  // 下载图片
  function download() {
    const canvas = document.getElementById('canvas')
    if (!canvas) return
    const MIME_TYPE = 'image/png'
    const imgURL = canvas.toDataURL(MIME_TYPE)
    const dlLink = document.createElement('a')
    dlLink.download = date + '运营数据'
    dlLink.href = imgURL
    dlLink.dataset.downloadurl = [
      MIME_TYPE,
      dlLink.download,
      dlLink.href
    ].join(':')

    document.body.appendChild(dlLink)
    dlLink.click()
    document.body.removeChild(dlLink)

    toast.success('运营数据下载成功，请在本地磁盘查看')
    setTimeout(() => {
      close()
    }, 3000)
  }
  return (
    <div className="com-modal data-table-modal">
      <div className="com-modal-wrap">
        <div className="modal-head">
          <div className="modal-title">运营数据：{date}</div>
          <div className="close-wrap" onClick={close}>
            <div className="close-icon"></div>
          </div>
        </div>
        <div className="modal-content">
          <div className="img-wrap">
            <TableCanvas date={date} displayWidth={700}/>
          </div>
          <div className="flex between btn-wrap">
            <div></div>
            <div className="plus-button no-icon" onClick={download}>
              <div className="download-icon"></div>
              <div>下载</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
