export default function DataGap({state, onChange}) {

  function handle(e) {
    onChange({dataGap: {
      ...state,
      [e.target.name]: e.target.value
    }})
  }

  return (
    <div className="data-gap-wrap f-12 flex align">
      <div className="blue1">数据筛选</div>
      <div className="input-group flex align">
        <div className="f-600">职位数区间</div>
        <input name="startPosCount" value={state.startPosCount} type="number" onChange={handle} />
        <div className="gap"></div>
        <input name="endPosCount" value={state.endPosCount} type="number" onChange={handle} />
      </div>
      <div className="input-group flex align">
        <div className="f-600">阅览量</div>
        <input name="startReadCount" value={state.startReadCount} type="number" onChange={handle} />
        <div className="gap"></div>
        <input name="endReadCount" value={state.endReadCount} type="number" onChange={handle} />
      </div>
      <div className="input-group flex align">
        <div className="f-600">新增粉丝</div>
        <input name="startAddFansCount" value={state.startAddFansCount} type="number" onChange={handle} />
        <div className="gap"></div>
        <input name="endAddFansCount" value={state.endAddFansCount} type="number" onChange={handle} />
      </div>
      <div className="input-group flex align">
        <div className="f-600">新增好友</div>
        <input name="startAddCount" value={state.startAddCount} type="number" onChange={handle} />
        <div className="gap"></div>
        <input name="endAddCount" value={state.endAddCount} type="number" onChange={handle} />
      </div>
      <div className="input-group flex align">
        <div className="f-600">当日付费</div>
        <input name="startTransformCount" value={state.startTransformCount} type="number" onChange={handle} />
        <div className="gap"></div>
        <input name="endTransformCount" value={state.endTransformCount} type="number" onChange={handle} />
      </div>
    </div>
  )
}