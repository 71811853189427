let index= 0
export default function CityPanel({ list = [] }) {
  index++
  const minWidth = Math.round(68 * 100 / 60)
  const data = list.map((item, index) => {
    const percent = Math.max(item.percent, 60)
    let width = Math.round(percent * minWidth / 100)
    // width = Math.min(width, 95)
    if (index === 0) {
      width = 98
    }
    return { ...item, width }
  })

  // 排第几名
  const thisIndex = data.findIndex(item => item.type === 1)
  let right = 0
  if (thisIndex > -1) {
    data.forEach((item, index) => {
      if (index > thisIndex) {
        right = right + item.width
      }
    })
  }


  return (
    <div className="city-panel">
      <div className="title flex align">
        <div className="icon"></div>
        <div className="f-14 heavy">达成比排名</div>
      </div>
      <div className="city-wrap">
        <div className="item-wrap f-12">
          {data.map((item, index) => (
            <div className={`item ${!!item.type}`} key={index} style={{ width: item.width + 'px' }}>
              <div>
                <div>{item.city} <span className="f-400">·</span> {item.percent}%</div>
                <div className="num">{item.count}</div>
              </div>
              {!!item.type && <div className={`rank-badge f-12 f-600 ${data.length < 3 ? '' : index > data.length - 3}`}>你的当前排名：{item.rank}</div>}
            </div>
          ))}
          <Ma key={index} index={thisIndex} right={right} />
        </div>
      </div>
    </div>
  )
}

const imgPath = 'https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/data/gif/'

function Ma({index, right}) {
  if (index === -1) {
    return null
  }
  let prefix = 1

  if (index > 2) {
    prefix = 2
  }
  if (index > 9) {
    prefix = 3
  }
  const random = Math.ceil(Math.random() * (prefix > 1 ? 3 : 4))
  prefix = imgPath + prefix + random

  let duration = parseFloat((right / 68 / 4).toFixed(1))
  duration = duration < 1.5 ? 1.5 : duration
  return (
    <div className="gif-icon" style={{right, animationDuration: duration + 's', backgroundImage: `url(${prefix}.GIF)`}}></div>
  )
}