import {useEffect, useState} from 'react'
import Breadcrumb from '../common/Breadcrumb'
import { useFastReducer } from '../components/hooks'
import showConfirm from '../components/confirm'
import ajax from '../ajax/dict'
import { Pagination } from 'antd'
import toast from '../components/toast'
import ChooseCity from '../components/form/ChooseCity'
import './dict.scss'

export default function CityList() {
  const [state, dispatch] = useFastReducer({
    page: 1,
    size: 15,
    total: 0,
    list: null,
    isModel: false,
    item: null,
    itemId: '',
  })

  //新增
  function add() {
    dispatch({ isModel: true, item: null })
  }
  //编辑
  function edit(item) {
    dispatch({ isModel: true, item: {id: item.cityId || undefined, name: item.cityName, pid: item.pid || undefined}, itemId: item.id})
  }
  //删除
  function delItem(id) {
    showConfirm(
      {
        title: '提示',
        msg: '是否确认删除该运营城市？',
      },
      () => {
        ajax.deleteCity(id).then(res => {
          if (res.success) {
            toast.success('删除成功')
            getList()
          }
        })
      },
    )
  }

  //获取列表
  function getList(page) {
    const params = {
      pageNum: page || state.page,
      pageSize: state.size,
    }
    ajax.getCityList(params).then(res => {
      if (res.success) {
        dispatch({
          list: res.data.records,
          total: res.data.total,
        })
      }
    })
  }

  // 分页切换
  function pageChange(page) {
    dispatch({page})
    getList(page)
  }
  // 操作回调
  function refresh() {
    if (state.item) {
      dispatch({isModel: false})
      getList()
    } else {
      dispatch({isModel: false, page: 1})
      getList(1)
    }
  }

  useEffect(function () {
    getList()
  }, [])

  return (
    <div className="com-content dict-list">
      <div className="com-fix-wrap">
        <div className="flex align between">
          <Breadcrumb path="/dict/city" />
          <div className="plus-button" onClick={add}>新增运营城市</div>
        </div>

        <div className='table-wrap'>
          <div className='table-head'>
            <div className='col-start'>城市</div>
            <div className='col-grow'>创建时间</div>
            <div className='col-end'>操作</div>
          </div>
          <div className='table-content'>
            {state.list && state.list.map((item, index) =>
              <div className='row' key={index}>
                <div className='col-start'>{item.cityName}</div>
                <div className='col-grow'>{item.createTime}</div>
                <div className='col-end'>
                  <div className='del-btn' onClick={() => delItem(item.id)}><div className='del-icon' />删除</div>
                  <div className='edit-btn' onClick={() => edit(item)}><div className='edit-icon' />编辑</div>
                </div>
              </div>
            )}
          </div>
        </div>
        {state.list && state.list.length > 0 && (
          <div className='pagination'>
            <Pagination align="end" showQuickJumper current={state.page} pageSize={state.size} total={state.total} onChange={pageChange} />
          </div>
        )}
      </div>
      {state.isModel && <EditModel close={() => dispatch({isModel: false})} item={state.item} itemId={state.itemId} refresh={refresh} />}
    </div>
  )
}

function EditModel({ close, item, itemId, refresh }) {
  const [city, setCity] = useState(item ? [item] : [])

  function confirm() {
    if (city.length === 0) {
      toast.warn('请选择运营城市')
      return
    }
    const c = city[0]
    const params = {
      cityName: c.name,
    }
    console.log(c)
    if (c.id) {
      params.cityId = c.id
    } else {
      params.pid = c.pid
    }
    if (item) {
      params.id = itemId
      //编辑
      ajax.editCity(params).then(res => {
        if (res.success) {
          toast.success('修改成功')
          refresh()
        }
      })
    } else {
      //新增
      ajax.addCity(params).then(res => {
        if (res.success) {
          toast.success('新增成功')
          refresh()
        }
      })
    }
  }

  return (
    <div className='dict-model'>
      <div className='city-wrap'>
        <div className='title-wrap'>
          <div className='title'>{item ? '编辑' : '新增'}运营城市</div>
          <div className='close' onClick={close}></div>
        </div>
        <div className="city-form">
          <div className="flex align">
            <div className='label c-75'>运营城市：</div>
            <div style={{width: '200px'}}>
              <ChooseCity single all value={city} onChange={v => setCity(v)} />
            </div>
          </div>
        </div>
        <div className='button-wrap'>
          <div className='confirm' onClick={confirm}>确认</div>
        </div>
      </div>
    </div>
  )
}