import {useFastReducer} from '../components/hooks'
import {DatePicker} from 'antd'
import './team.scss'
import dayjs from 'dayjs'
import {useRef,useEffect} from 'react'
import ajax from '../ajax/dashboard'
import {week} from '../components'

const weekArray = ['', '一', '二', '三', '四', '五']

export default function Team() {
  const [state, dispatch] = useFastReducer({
    date: dayjs(),
    data: {},
  })

  function disabledDate(current) {
    return current && current > dayjs().endOf('day')
  }

  useEffect(() => {
    const date = state.date.format('YYYY-MM-DD')
    ajax.getTeamData({date}).then(res => {
      if (res.success) {
        dispatch({data: res.data})
      }
    })
  }, [state.date])

  const d = state.data
  const weekTarget = d.teamWeekList || []
  const chartData = d.dataLineList || null

  return (
    <div className="com-content">
      <div className="team-page">
        <div className="head-title flex align between">
          <div className="f-600">
            团队数据分析看板
            {d.month && <span className="blue"> | {d.month}月第{weekArray[d.week]}周（{d.lastWorkDate}星期{week(d.weekFew)}）</span>}
          </div>
          <DatePicker value={state.date} disabledDate={disabledDate} onChange={date => dispatch({date})} allowClear={false} />
        </div>
        {/*上半部分*/}
        <div className="panel-wrap flex f-12">
          {/*第一个 月*/}
          <div className="panel">
            <div className="caption">
              <div className="label">月</div>
              <span>本月目标:</span>
              <span className="f-14 heavy indigo">{d.teamMonthNum}</span>
            </div>
            <div className="num-section">
              <HalfPieCharts num={d.monthNum} data={[d.teamMonthPercent,d.noTeamMonthPercent]} />
            </div>
            <div className="data-section light">
              <div className="data-wrap">
                <div className="first-group">
                  进度标准：<span className="c-0 f-600">{d.monthStandardNum}</span> | {d.monthStandardPercent}%
                  <br/>
                  进度差值：<span className="c-0 f-600">{d.monthPoorNum}</span> | {d.monthPoorPercent}%
                </div>
                <div className="first-group">
                  上月同期：<span className="c-0 f-600">{d.lastMonthNum}</span> | {d.lastMonthNumPercent}%
                  <br/>
                  同期差值：<span className="c-0 f-600">{d.lastMonthPoorNum}</span> | {d.lastMonthPoorPercent}%
                </div>
              </div>
            </div>
          </div>
          {/*第二个 周*/}
          <div className="panel">
            <div className="caption">
              <div className="label">周</div>
              <span>周目标:</span>
              <span className="f-14 heavy indigo">{d.weekStandardNum}</span>
            </div>
            <div className="num-section">
              <div className="big-number-wrap">
                <span className="heavy indigo big-number">{d.weekNum}</span>个
              </div>
              <div>
                <span className="light">已完成 </span>
                <span className="green f-600">{d.weekPercent}%</span>
              </div>
              <div>
                <div className="progress">
                  <div className="value" style={{width: d.weekPercent + '%'}}></div>
                </div>
              </div>
            </div>
            <div className="data-section blue-bg">
              <div className="week-section light">
                {weekTarget.map((item, index) => (
                  <div key={index}>
                    <div className="week-line">
                      第{weekArray[index + 1]}周：完成<span className="c-0 f-600">{item.tweekNum}</span> | {item.tweekPercent}%
                    </div>
                    <div className="percent">
                      <div className="value" style={{width: item.tweekPercent + '%'}}></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/*第三个 日*/}
          <div className="panel">
            <div className="caption">
              <div className="label">日</div>
              <span>日目标:</span>
              <span className="f-14 heavy indigo">{d.beforeDayStandardNum}</span>
            </div>
            <div className="num-section">
              <div className="big-number-wrap">
                <span className="heavy indigo big-number">{d.beforeDayNum}</span>个
              </div>
              <div>
                <span className="light">已完成 </span>
                <span className="green f-600">{d.beforeDayPercent}%</span>
              </div>
              <div>
                <div className="progress">
                  <div className="value" style={{width: d.beforeDayPercent + '%'}}></div>
                </div>
              </div>
            </div>
            <div className="data-section blue-bg light">
              <div className="data-wrap">
                <div className="first-group">
                  进度差值：<span className="c-0 f-600">{d.dayStandardPoorNum} | {d.dayStandardPoorPercent}%</span>
                  <br/>
                  相较前日：<Compare num={d.dayPoorNum} />
                </div>
                <div className="first-group">
                  当日最高：
                  {d.dayHighName ? (
                    <>
                      <span className="c-0 f-600">{d.dayHighName}</span> <span className="red f-600">{d.dayHighNum}</span><span className="c-0 f-600">个</span>
                    </>
                  ): '- -'}
                  <br/>
                  当日最低：
                  {d.dayLowName ? (
                    <>
                      <span className="c-0 f-600">{d.dayLowName}</span> <span className="red f-600">{d.dayLowNum}</span><span className="c-0 f-600">个</span>
                    </>
                  ) : '- -'}
                </div>
              </div>
            </div>
          </div>
          {/*第四个 剩余*/}
          <div className="panel gray-bg">
            <div className="caption">
              <div className="label">剩余</div>
              <span>日均目标:</span>
              <span className="f-14 heavy indigo">{d.dayTarget}</span>
            </div>
            <div className="num-section">
              <div className="big-number-wrap">
                <span className="heavy c-0 big-number">{d.residualCount}</span>个
              </div>
              <div>
                <span className="light">未完成 </span>
                <span className="c-0 f-600">{d.noTeamMonthPercent}%</span>
              </div>
              <div>
                <div className="progress dark">
                  <div className="value" style={{width: d.noTeamMonthPercent + '%'}}></div>
                </div>
              </div>
            </div>
            <div className="data-section">
              <div>
                <div className="count-day">
                  <div className="c-0">剩余工作日：</div>
                  <div className="day-badge f-600"><span className="f-14">{d.residualDay}</span> · DAY</div>
                </div>
                {d.residualDay ? (
                  <div className="dot-wrap">
                    <div className="dot-line">
                      追赶目标100%：日均 <span className="f-600">{d.dayCount1}</span>｜人均 <span className="f-600">{d.personCount1}</span>
                    </div>
                    <div className="dot-line">
                      追赶目标&ensp;90%：日均 <span className="f-600">{d.dayCount2}</span>｜人均 <span className="f-600">{d.personCount2}</span>
                    </div>
                    <div className="dot-line">
                      追赶目标&ensp;80%：日均 <span className="f-600">{d.dayCount3}</span>｜人均 <span className="f-600">{d.personCount3}</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="blank"></div>
                    <div className="day-tip red f-10 f-600">11月，全力以赴做好每一个运营动作，一定会更好！</div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*下半部分*/}
        <div className="bottom-half flex">
          <div className="chart-panel">
            <div className="title-wrap flex align between">
              <div className="title">
                <div className="text f-600 f-16">本月团队日数据对比</div>
              </div>
              <div className="badge f-12 f-600">日均:{d.dayCount}个 ｜ 人均:{d.personCount}个</div>
            </div>
            {/* 折线图 */}
            <div className='desc-wrap'>
              <div className='desc-title'>完成度</div>
              <div className='label green'>120以上</div>
              <div className='label red'>120个以下<span className='gray'>（单位：个）</span></div>
            </div>
            <LineBarCharts data={chartData} />
          </div>
          {/*排行榜*/}
          <div className="rank-panel">
            <div className="flex first-line">
              <Rank title="团队贡献值最高" up list={d.list1} />
              <Rank title="个人达成比最高" up list={d.list3} />
            </div>
            <div className="flex">
              <Rank title="团队贡献值最低" list={d.list2} />
              <Rank title="个人达成比最低" list={d.list4} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Compare({num}) {
  if (num === 0) {
    return <span className="c-0">0</span>
  }
  if (num > 0) {
    return (
      <>
        <span className="green f-600">{num}</span><span className="icon-up"></span>
      </>
    )
  }
  if (num < 0) {
    return (
      <>
        <span className="red f-600">{num}</span><span className="icon-down"></span>
      </>
    )
  }
}

// 4个排行榜
function Rank({title, up, list = []}) {
  return (
    <div className={up ? 'rank' : 'rank false'}>
      <div className="title flex align">
        <div className="c-0 f-600">{title}</div>
        {up ? <div className="icon-up"></div> : <div className="icon-down"></div>}
      </div>
      {list.map((item, index) => (
        <div key={index} className="item">
          <div className="index">
            {up ? <div className={`ord ord-${index}`}></div> : index + 1}
          </div>
          <div className="city">{item.city}</div>
          <div className="name">{item.username}</div>
          <div className="number">{item.num}</div>
          <div className="percent">{item.percent}</div>
        </div>
      ))}
    </div>
  )
}

//图表
let lineBarChart
function LineBarCharts({ data }) {
  const chartRef = useRef()

  function refreshLine() {
    lineBarChart.resize()
  }

  let res = new ResizeObserver(() => {
    lineBarChart.resize()
  })

  useEffect(function () {
    lineBarChart = window.echarts.init(chartRef.current)
    window.addEventListener('resize', refreshLine)
    res.observe(chartRef.current)
    return () => {
      window.removeEventListener('resize', refreshLine)
    }
  }, [])

  const series = []
  if (data) {
    //数据
    let newData = data.map(item => item.num)
    newData = newData.map(item => {
      if (item < 120) {
        return {
          value: item,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#D26A63' },
              { offset: 1, color: '#FFFFFF' }
            ])
          },
          label: { color: '#D26A63' },
        }
      }
      return item
    })
    series.push({
      name: '完成数',
      type: 'bar',
      barWidth: '5px',
      label: { show: true, position: 'top', color: '#CBCBCB', fontSize: 12, fontWeight: 600, },
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#A4D252' },
          { offset: 1, color: '#FFFFFF' }
        ]),
      },
      data: newData
    })
    series.push({
      name: '趋势',
      type: 'line',
      itemStyle: {
        lineStyle: {
          color: '#edace6' //改变折线颜色
        },
        color: '#edace6',
      },
      data: newData,
    })

    if (lineBarChart) {
      lineBarChart.clear() //清除缓存数据
      const option = {
        animation: false,
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          top: 20,
          right: '3%',
          itemWidth: 20,
          itemHeight: 8,
          textStyle: {
            fontSize: 10
          },
          data: ['完成数', '趋势']
        },
        grid: {
          // top:60,
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: data.map(item => item.time),
          axisLabel: {
            color: '#B0ADD2',
            fontWeight: 600,
          },
          axisLine: {
            lineStyle: {
              color: '#B0ADD2',
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontWeight: 600,
            color: '#B0ADD2'
          }
        },
        series: series
      }
      //绘制
      lineBarChart.setOption(option)
    }
  }
  return <div ref={chartRef} className="line-chart"></div>
}
function HalfPieCharts({ num, data }) {
  const chartRef = useRef()
  if (num) {
    let myChart = window.echarts.init(chartRef.current)
    let newData = [
      {
        value: data[0],
        label: { rotate: (data[1] / (data[0] * 1 + data[1] * 1) * 90) }
      },
      {
        value: data[1],
        label: { rotate: -(data[0] / (data[0] * 1 + data[1] * 1) * 90) }
      },
    ]

    const option = {
      animation: false,
      series: [
        {
          name: 'Access From',
          type: 'pie',
          emphasis: {
            disabled: true,
          },
          radius: ['100%', '140%'],
          center: ['50%', '80%'],
          color: ['#74be8b', '#a4d252'],
          itemStyle: {
            borderRadius: 5,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            position: 'inner',
            formatter: '{d}%',
            color: '#ffffff',
            fontSize: 9
          },
          labelLayout: {
            dy: 5
          },
          startAngle: 180,
          endAngle: 360,
          data: newData,
        }
      ]
    }
    myChart.clear()
    myChart.setOption(option)
  }

  return (
    <div className='half-pie'>
      <div ref={chartRef} className="half-pie-chart"></div>
      <div className='num heavy'>{num}</div>
      <div className='label-wrap'>
        <div className='label true'>已完成</div>
        <div className='label'>未完成</div>
      </div>
    </div>
  )
}