import {useEffect} from 'react'
import {useFastReducer} from '../components/hooks'
import Breadcrumb from '../common/Breadcrumb'
import Input from '../components/form/Input'
import Text from '../components/form/Text'
import AssociateInput from './AssociateInput'
import {DatePicker, TimePicker, Select} from 'antd'
import HRForm from './HRForm'
import PosForm from './PosForm'
import commonAjax from '../ajax/common'
import operateAjax from '../ajax/operateData'
import {useSearchParams, useNavigate, useParams} from 'react-router-dom'
import {user, replaceItemAtIndex, salaryChar, cityChar, cityStr, removeItemAtIndex, epLocationList} from '../components'
import toast from '../components/toast'
import ChooseCity from '../components/form/ChooseCity'
import PosListModal from './PosListModal'
import './operateForm.scss'
import dayjs from 'dayjs'

let timer // 联想输入定时器

export default function OperateForm() {
  const navigate = useNavigate()
  const [qs] = useSearchParams()

  const {id} = useParams()
  const userId = Number(qs.get('userId'))
  const [state, dispatch] = useFastReducer({
    isManager: user && user.isManage === 1,
    city: qs.get('city') || '',
    cityId: Number(qs.get('cityId')),
    cityDictId: Number(qs.get('cityDictId')),
    publishDate: dayjs().subtract(1, 'day').hour(12).minute(40), // 发布时间
    contentMaker: null, // 内容制作人
    makerList: [],
    epName: '', // 企业名称
    epNameList: null,
    epChar: null, // 企业性质
    epCharList: [{label: '私营', value: '私营'}, {label: '国企/央企', value: '国企/央企'}],
    epText: '', // 企业简介
    epCate: null, // 行业
    epCateList: [],
    epBrand: '', // 品牌
    epLocation: null, // 组织位置
    epLocationList,
    epCity: [],
    // HR信息
    isHR: false,
    HR: null,
    HRAdd: true,
    HRList: [],
    HRIndex: -1,
    // 职位信息
    posCount: 0,
    isPos: false,
    pos: null,
    isPosModal: null, // 选择过往职位弹窗
    posList: [],
    // 公众号数据
    pFan: '', pAdd: '', pLan: '',
    // 微信数据
    isBefore: false,
    wxListBefore: [
      {friendCount: '', addCount: '', transformCount: ''}
    ],
    wxList: [],
    // B端开拓
    bAdd: '', bGroup: '', bEp: '', bName: '',
    // C端裂变
    cGroup: '', cExpand: '',
    // 运营动作
    oCircle: '', oSelf: '', oEx: '', oHr: '',
    hrString: [
      {name: 'HR个人'},
      {name: 'HR部门'},
      {name: '业务部门'},
      {name: '全公司'},
      {name: '无扩散'},
    ],
  })

  useEffect(() => {
    if (id) {
      operateAjax.getDetail(Number(id)).then(res => {
        if (res.success) {
          const d = res.data || {}
          const temp = d.hrRedirect ? d.hrRedirect.split(',') : []
          const isBefore = dayjs(d.publishTime).isBefore(dayjs('2024-08-01'))
          const payload = {
            userId: d.userId,
            city: d.cityName,
            cityId: d.cityId,
            cityDictId: d.cityId2,
            publishDate: dayjs(d.publishTime),
            contentMaker: d.createUser,
            epName: d.enterpriseName,
            epChar: d.property,
            epCate: d.businessId,
            epBrand: d.brand,
            epLocation: d.organization,
            epCity: [{id: d.enterpriseCityId, name: d.enterpriseCity}],
            epText: d.intro,
            HRIndex: 0,
            HRList: [
              {id: d.hrId, name: d.name, job: d.job, leaderJob: d.leaderJob, email: d.email, phone: d.phone, updateTime: d.updateTime}
            ],
            posList: d.operatePosList || [],
            pLan: d.readCount,
            pFan: d.fansCount,
            pAdd: d.addCount,
            isBefore,
            bAdd: d.hrCount,
            bGroup: d.addHrCommunityCount,
            bEp: d.enterpriseCount,
            bName: d.enterpriseNames,
            cGroup: d.groupCount,
            cExpand: d.diffusionCount,
            oCircle: d.friendCount || '',
            oSelf: d.selfCommunityCount || '',
            oEx: d.externalCommunityCount || '',
            oHr: d.hrCommunityCount || '',
            hrString: state.hrString.map(item => ({...item, check: temp.includes(item.name)}))
          }
          if (isBefore) {
            payload.wxListBefore = d.wxDataList
            getWXListByCity(d.cityId)
          } else {
            payload.wxList = d.wxDataList
          }
          dispatch(payload)
        }
      })
    } else {
      // 默认企业所在城市
      if (state.cityDictId) {
        dispatch({epCity: [{id: state.cityDictId, name: state.city}]})
      }
      getWXListByCity(state.cityId)
    }
    // 行业
    const params = state.cityId ? {cityId: state.cityId} : {}
    commonAjax.getDict(params).then(res => {
      if (res.success) {
        const epCateList = res.data[3].map(item => ({label: item.name, value: item.id}))
        const makerList = res.data[4].map(item => ({label: item.name, value: item.name}))
        if (params.cityId) {
          dispatch({epCateList, makerList, contentMaker: makerList[0].value})
        } else {
          dispatch({epCateList, makerList})
        }
      }
    })
  }, [])

  function getWXListByCity(cityId) {
    // 城市下微信列表
    operateAjax.getWXList(cityId).then(res => {
      if (res.success) {
        const wxList = res.data.wxDataList || []
        wxList.forEach(item => {
          item.accountId = item.id
          item.friendCount = ''
          item.addCount = ''
          item.transformCount = ''
        })
        dispatch({wxList})
      }
    })
  }

  function setDate(d) {
    dispatch({
      publishDate: d.hour(state.publishDate.hour()).minute(state.publishDate.minute()),
      isBefore: d.isBefore(dayjs('2024-08-01'))
    })
  }

  // 联想企业名称
  function searchEpName(value) {
    dispatch({epName: value})
    if (value) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        operateAjax.searchEp(value).then(res => {
          if (res.success && res.data.length > 0) {
            dispatch({epNameList: res.data})
          }
        })
      }, 400)
    } else {
      dispatch({epNameList: null})
    }
  }
  // 选择已存在企业
  function selectEpName(obj) {
    dispatch({
      epName: obj.enterpriseName,
      epNameList: null,
      epChar: obj.property,
      epText: obj.intro || '',
      epCate: obj.businessId,
      epBrand: obj.brand,
      epLocation: obj.organization,
      epCity: [{id: obj.cityId, name: obj.city}],
      posCount: obj.posCount,
      enterpriseId: obj.id,
    })
    operateAjax.getHRList(obj.id).then(res => {
      if (res.success) {
        const temp = state.HRList.filter(item => !item.id)
        dispatch({HRList: [...temp, ...res.data], HRIndex: 0})
      }
    })
  }
  // 新增HR
  function setHR(obj) {
    if (state.HR) { // 编辑
      const index = state.HRList.findIndex(item => item.id === obj.id)
      let HRList = [...state.HRList]
      if (index > -1) {
        HRList = replaceItemAtIndex(state.HRList, index, obj)
      }
      dispatch({
        isHR: false,
        HR: null,
        HRList,
      })
    } else { // 新增
      dispatch({
        isHR: false,
        HRAdd: false,
        HRIndex: 0,
        HRList: [obj, ...state.HRList]
      })
    }
  }
  // 新增职位
  function setPos(obj) {
    let posList
    if (obj.index !== undefined) {
      posList = replaceItemAtIndex(state.posList, obj.index, obj)
    } else {
      posList = [obj, ...state.posList]
    }
    dispatch({
      isPos: false,
      posList,
    })
  }
  // 选择过往职位
  const ids = []
  state.posList.forEach(item => {
    if (item.id) {
      ids.push(item.id)
    }
  })
  function choosePos(list) {
    dispatch({
      isPosModal: null,
      posList: [...list, ...state.posList.filter(item => !item.id)]
    })
  }
  function delPos(index) {
    dispatch({posList: removeItemAtIndex(state.posList, index)})
  }
  // 填写微信数据
  function setWxList(index, value) {
    if (state.isBefore) {
      dispatch({wxListBefore: replaceItemAtIndex(state.wxListBefore, index, {...state.wxListBefore[index], ...value})})
    } else {
      dispatch({wxList: replaceItemAtIndex(state.wxList, index, {...state.wxList[index], ...value}) })
    }
  }
  // 多选HR转发
  function checkHrStr(i) {
    const item = state.hrString[i]
    if (item.disabled) return
    if (i === 4) {
      const check = !state.hrString[4].check
      const hrString = state.hrString.map(it => ({...it, check: false, disabled: check}))
      hrString[4].check = check
      hrString[4].disabled = false
      dispatch({hrString})
    } else {
      dispatch({hrString: replaceItemAtIndex(state.hrString, i, {...item, check: !item.check})})
    }
  }
  // 取消创建表单
  function cancel() {
    navigate(-1)
  }

  const wxList = state.isBefore ? state.wxListBefore : state.wxList

  function submit() {
    if (!state.publishDate) {
      toast.warn('请选择发布日期')
      return
    }
    if (!state.contentMaker) {
      toast.warn('请填写内容制作人')
      return
    }
    if (!state.epName) {
      toast.warn('请填写企业名称')
      return
    }
    if (!state.epChar) {
      toast.warn('请选择企业性质')
      return
    }
    if (!state.epCate) {
      toast.warn('请选择行业类别')
      return
    }
    if (!state.epBrand) {
      toast.warn('请填写品牌')
      return
    }
    if (!state.epLocation) {
      toast.warn('请选择组织位置')
      return
    }
    if (state.epCity.length === 0) {
      toast.warn('请选择企业所在城市')
      return
    }
    if (!state.epText) {
      toast.warn('请填写企业简介')
      return
    }
    if (state.HRIndex === -1) {
      toast.warn('请新增或选择一个HR信息')
      return
    }
    if (state.posList.length === 0) {
      toast.warn('请新增职位信息')
      return
    }
    if (state.pFan === '' || state.pAdd === '' || state.pLan === '') {
      toast.warn('请完善公众号数据')
      return
    }
    if (wxList.length > 0) {
      let flag = false
      wxList.forEach(it => {
        if (it.friendCount === '' || it.addCount === '' || it.transformCount === '') {
          flag = true
        }
      })
      if (flag) {
        toast.warn('请完善微信数据')
        return
      }
    }
    // if (state.bAdd === '' || state.bGroup === '' || state.bEp === '') {
    //   toast.warn('请完善B端开拓数据')
    //   return
    // }
    const hrString = state.hrString.filter(item => item.check)
    // if (state.oCircle === '' || state.oSelf === '' || state.oEx === '' || state.oHr === '' || hrString.length === 0) {
    //   toast.warn('请完善运营动作数据')
    //   return
    // }
    let params = {
      publishTime: state.publishDate.format('YYYY-MM-DD HH:mm'),
      createUser: state.contentMaker,
      enterpriseName: state.epName,
      property: state.epChar,
      intro: state.epText,
      businessId: state.epCate,
      brand: state.epBrand,
      organization: state.epLocation,
      enterpriseCityId: state.epCity[0].id,
      cityId: Number(state.cityId),
      readCount: Number(state.pLan),
      fansCount: Number(state.pFan),
      addCount: Number(state.pAdd),

      hrCount: Number(state.bAdd),
      addHrCommunityCount: Number(state.bGroup),
      enterpriseCount: Number(state.bEp),
      enterpriseNames: state.bName,

      groupCount: Number(state.cGroup),
      diffusionCount: Number(state.cExpand),
    }

    if (state.isManager) {
      params.friendCount = Number(state.oCircle)
      params.selfCommunityCount = Number(state.oSelf)
      params.externalCommunityCount = Number(state.oEx)
      params.hrCommunityCount = Number(state.oHr)
      params.hrRedirect = hrString.map(it => it.name).join(',')
    }

    if (user.isManage === 1 && userId) {
      params.userId = userId
    }

    // HR信息
    const hr = state.HRList[state.HRIndex]
    params.name = hr.name
    params.job = hr.job
    params.leaderJob = hr.leaderJob
    params.phone = hr.phone
    params.email = hr.email
    if (hr.id) {
      params.hrId = hr.id
    }

    params.operatePosList = state.posList
    const wxDataList = state.isBefore ? state.wxListBefore : state.wxList
    params.wxDataList = wxDataList.map(it => ({
      ...it,
      cityId: Number(state.cityId),
      friendCount: Number(it.friendCount),
      addCount: Number(it.addCount),
      transformCount: Number(it.transformCount),
    }))

    if (id) {
      params.id = Number(id)
      params.userId = state.userId
      operateAjax.update(params).then(res => {
        if (res.success) {
          toast.success('修改成功')
          setTimeout(() => {
            navigate(-1)
          }, 1500)
        }
      })
    } else {
      operateAjax.add(params).then(res => {
        if (res.success) {
          toast.success('创建表单成功')
          setTimeout(() => {
            navigate(-1)
          }, 1500)
        }
      })
    }
  }

  return (
    <div className="com-content operate-form">
      <div className="com-fix-wrap">
        <Breadcrumb path="/operate-data/form" id={id} />
        <div className="form-panel">
          <div className="caption">{id ? '编辑' : '创建'}表单</div>
          {/*基础信息*/}
          <div className="wrap-wrap">
            <div className="title">基础信息</div>
            <div className="flex between basic-info">
              <div className="input-group">
                <div className="label required">发布时间：</div>
                <DatePicker value={state.publishDate} onChange={setDate} />
                <div className="gap"></div>
                <TimePicker
                  value={state.publishDate}
                  format="HH:mm"
                  needConfirm={false}
                  onChange={d => dispatch({publishDate: d})}
                />
              </div>
              <div className="input-group content-maker">
                <div className="label required">内容制作人：</div>
                <Select placeholder="请选择" value={state.contentMaker} options={state.makerList} onChange={v => dispatch({contentMaker: v})} />
              </div>
            </div>
            <div className="title">企业信息</div>
            <div className="input-line flex between" style={{marginRight: '-20px'}}>
              <div className="input-group">
                <div className="label required">企业发布全称：</div>
                <div className="ep-name-wrap">
                  <AssociateInput name="epName" value={state.epName} list={state.epNameList} onChange={searchEpName} dispatch={dispatch} select={selectEpName} />
                </div>
              </div>
              <div className="input-group">
                <div className="label required">企业性质：</div>
                <Select placeholder="请选择" value={state.epChar} options={state.epCharList} onChange={v => dispatch({epChar: v})} />
              </div>
            </div>
          </div>
          <div className="input-line flex between">
            <div className="input-group">
              <div className="label required">行业类别：</div>
              <Select placeholder="请选择" value={state.epCate} options={state.epCateList} onChange={v => dispatch({epCate: v})} />
            </div>
            <div className="input-group">
              <div className="label required">品牌：</div>
              <div style={{width: '100px'}}>
                <Input placeholder="请输入" value={state.epBrand} onChange={v => dispatch({epBrand: v})} />
              </div>
            </div>
            <div className="input-group">
              <div className="label required">组织位置：</div>
              <Select placeholder="请选择" value={state.epLocation} options={state.epLocationList} onChange={v => dispatch({epLocation: v})} />
            </div>
            <div className="input-group">
              <div className="label required">所在城市：</div>
              <div style={{width: '186px'}}>
                <ChooseCity value={state.epCity} single={true} onChange={v => dispatch({epCity: v})} />
              </div>
            </div>
          </div>
          <div className="wrap-wrap">
            <div className="input-line">
              <div className="input-group with-text">
                <div className="label required">企业简介：</div>
                <Text value={state.epText} placeholder="请输入企业简介" onChange={v => dispatch({epText: v})} />
              </div>
            </div>
          </div>
          {/*HR信息*/}
          <div className="flex align between">
            <div className="title required">HR信息</div>
            {state.HRAdd && (
              <div className="add-button" onClick={() => dispatch({isHR: true, HR: null})}>
                <div className="add-icon"></div>
                <div>新增HR信息</div>
              </div>
            )}
          </div>
          <div className="hr-wrap">
            {state.HRList.map((item, i) => (
              <div key={i} className={`hr-item f-12 ${item.id ? '' : 'new'}`} onClick={() => dispatch({HRIndex: i})}>
                <div className={`com-check ${state.HRIndex === i}`}></div>
                <div className="name-wrap flex align">
                  {!item.id && <div className="new-badge">新增</div> }
                  <div className="f-14 f-600">{item.name}</div>
                  {item.job && <div className="gap f-500">|</div>}
                  <div className="f-500">{item.job}</div>
                </div>
                <div className="job-wrap flex">
                  <div className="c-75">直属领导职务：</div>
                  <div className="f-500">{item.leaderJob || '--'}</div>
                </div>
                <div className="email-wrap flex">
                  <div className="c-75">Email：</div>
                  <div className="f-500">{item.email || '--'}</div>
                </div>
                <div className="tel-wrap flex">
                  <div className="c-75">Tel：</div>
                  <div className="f-500">{item.phone || '--'}</div>
                </div>
                {item.id && (
                  <div className="time-wrap">
                    HR最新对接：{item.updateTime}
                  </div>
                )}
                {(state.isManager || !item.id) && (
                  <div className="edit-btn" onClick={() => dispatch({isHR: true, HR: item})}>
                    <div className="edit-icon"></div>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="flex align between">
            <div className="title required">职位信息</div>
            <div className="add-button green" onClick={() => dispatch({isPos: true, pos: null})}>
              <div className="add-icon"></div>
              <div>新增职位信息</div>
            </div>
          </div>
          {state.posCount > 0 && (
            <div className="old-pos">
              <div>查询过往共发过<span className="f-18 f-600">{state.posCount}</span>个职位</div>
              <div className="pos-btn" onClick={() => dispatch({isPosModal: state.enterpriseId})}>点击选择</div>
            </div>
          )}
          <div className="pos-list">
            {state.posList.map((item, i) => (
              <div key={i} className={`pos-item ${item.id ? '' : 'new'}`}>
                <div className="name-wrap">
                  {!item.id && <div className="new-badge">新增</div>}
                  <div className="name">{item.name}</div>
                  <div>{salaryChar(item)}</div>
                </div>
                <div className="cate-wrap">{item.businessName} | {item.category}</div>
                <div className="city-wrap">
                  <div className="c-75">城市：</div>
                  <div>{item.city ? cityStr(item.city) : cityChar(item.cityList)}</div>
                </div>
                <div className="link f-600" onClick={() => dispatch({isPos: true, pos: {...item, index: i}})}>查看/编辑</div>
                <div className="del-wrap" onClick={() => delPos(i)}>
                  <div className="del-btn"></div>
                </div>
              </div>
            ))}
          </div>

          <div className="title required">公众号数据</div>
          <div className="data-form-item">
            <div className="city-wrap">
              <div className="city-badge">绑定城市：{state.city}</div>
            </div>
            <div className="input-group">
              <div className="label">头条浏览量：</div>
              <Input type="number" value={state.pLan} onChange={v => dispatch({pLan: v})} />
            </div>
            <div className="input-group">
              <div className="label">总粉丝：</div>
              <Input type="number" value={state.pFan} onChange={v => dispatch({pFan: v})} />
            </div>
            <div className="input-group">
              <div className="label">新增：</div>
              <Input type="number" value={state.pAdd} onChange={v => dispatch({pAdd: v})} />
            </div>
          </div>

          <div className="title required">微信数据</div>
          <div className="wx-list-wrap">
            {wxList.map((item, i) => (
              <div key={i} className="data-form-item">
                <div className="city-wrap">
                  {state.isBefore ? <div className="city-badge">绑定城市：{state.city}</div> :
                    <div className="city-badge">{state.city}-{item.name}</div>
                  }
                </div>
                <div className="input-group">
                  <div className="label">总好友：</div>
                  <Input type="number" value={item.friendCount} onChange={v => setWxList(i, {friendCount: v}) } />
                </div>
                <div className="input-group">
                  <div className="label">新增：</div>
                  <Input type="number" value={item.addCount} onChange={v => setWxList(i,{addCount: v})} />
                </div>
                <div className="input-group">
                  <div className="label">转化：</div>
                  <Input type="number" value={item.transformCount} onChange={v => setWxList(i,{transformCount: v})} />
                </div>
              </div>
            ))}
          </div>

          <div className="title">B端开拓(当日) <span className="gray f-14 f-500">· 选填</span></div>
          <div className="flex b-wrap">
            <div className="input-group">
              <div className="label">新增HR好友：</div>
              <Input type="number" value={state.bAdd} onChange={v => dispatch({bAdd: v})} />
              <div className="unit">个</div>
            </div>
            <div className="input-group">
              <div className="label">新加HR社群：</div>
              <Input type="number" value={state.bGroup} onChange={v => dispatch({bGroup: v})} />
              <div className="unit">个</div>
            </div>
            <div className="input-group">
              <div className="label">新发布企业：</div>
              <Input type="number" value={state.bEp} onChange={v => dispatch({bEp: v})} />
              <div className="unit">家</div>
            </div>
            <div className="input-group ep-name">
              <div className="label">新发布企业名称：</div>
              <Input type="text" value={state.bName} onChange={v => dispatch({bName: v})} />
            </div>
          </div>

          <div className="title">C端裂变 <span className="gray f-14 f-500">· 选填</span></div>
          <div className="flex c-split">
            <div className="input-group">
              <div className="label">拉群：</div>
              <Input type="number" value={state.cGroup} onChange={v => dispatch({cGroup: v})} />
              <div className="unit">次</div>
            </div>
            <div className="input-group">
              <div className="label">扩散：</div>
              <Input type="number" value={state.cExpand} onChange={v => dispatch({cExpand: v})} />
              <div className="unit">次</div>
            </div>
          </div>

          {state.isManager && (
            <>
              <div className="title">运营动作 <span className="gray f-14 f-500">· 选填</span></div>
              <div className="flex">
                <div className="input-group">
                  <div className="label">扩散动作(次数)：</div>
                </div>
                <div className="flex o-wrap">
                  <div className="input-group">
                    <div className="label">朋友圈：</div>
                    <Input type="number" value={state.oCircle} onChange={v => dispatch({oCircle: v})} />
                    <div className="unit">次</div>
                  </div>
                  <div className="input-group">
                    <div className="label">自建社群：</div>
                    <Input type="number" value={state.oSelf} onChange={v => dispatch({oSelf: v})} />
                    <div className="unit">次</div>
                  </div>
                  <div className="input-group">
                    <div className="label">外部社群：</div>
                    <Input type="number" value={state.oEx} onChange={v => dispatch({oEx: v})} />
                    <div className="unit">次</div>
                  </div>
                  <div className="input-group">
                    <div className="label">HR社群：</div>
                    <Input type="number" value={state.oHr} onChange={v => dispatch({oHr: v})} />
                    <div className="unit">次</div>
                  </div>
                </div>
              </div>
              <div className="input-group hr-expand">
                <div className="label">HR扩散(有谁扩散)：</div>
                <div className="flex hr-group">
                  {state.hrString.map((item, i) => (
                    <div key={i} className={`check-item ${item.disabled}`} onClick={() => checkHrStr(i)}>
                      <div className={`com-check ${item.check}`}></div>
                      <div>{item.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          <div className="action-wrap">
            <div className="button hollow" onClick={cancel}>取消</div>
            <div className="button block" onClick={submit}>确认{id ? '修改' : '创建'}</div>
          </div>
        </div>
      </div>

      {state.isHR && <HRForm close={() => dispatch({isHR: false})} hr={state.HR} onChange={setHR} />}
      {state.isPos && <PosForm close={() => dispatch({isPos: false})} pos={state.pos} city={state.cityDictId ? {id: state.cityDictId, name: state.city} : null} onChange={setPos} />}
      {state.isPosModal && <PosListModal close={() => dispatch({isPosModal: null})} ids={ids} type="ep" id={state.isPosModal} onChange={choosePos} />}
    </div>
  )
}