import {Select} from 'antd'
import {useFastReducer} from '../hooks'
import {useEffect} from 'react'

const salaryUnit = ['', '元', 'K', 'W']
const year = [
  5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 30,
  35, 40, 45, 50, 60, 70, 80, 90, 100
]
const month = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
  35, 40, 45, 50, 55, 60, 70, 80, 90, 100
]
const day = [
  60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 200, 250, 300,
  350, 400, 450, 500, 550, 600, 700, 800, 900, 1000
]

export default function Salary({type, from, to, onChange}) {
  const [state, dispatch] = useFastReducer({
    typeList: [
      {label: '面议', value: 0},
      {label: '日薪', value: 1},
      {label: '月薪', value: 2},
      {label: '年薪', value: 3},
    ],
    fromArray: [],
    toArray: [],
  })

  function changeType(value) {
    onChange({salaryType: value, salaryMin: null, salaryMax: null})
  }

  function changeFrom(from) {
    onChange({salaryMin: from})
  }

  function changeTo(to) {
    onChange({salaryMax: to})
  }

  useEffect(function() {
    if (type === 0) {
      return
    }
    let fromArray
    if (type === 1) {
      fromArray = day
    }
    if (type === 2) {
      fromArray = month
    }
    if (type === 3) {
      fromArray = year
    }
    fromArray = fromArray.map(it => ({label: it + salaryUnit[type], value: it}))
    let toArray = fromArray.slice(1)
    if (from) {
      const index = toArray.findIndex(it => it.value === from)
      toArray = toArray.slice(index + 1)
      if (to && toArray.findIndex(item => item.value === to) === -1) {
        onChange({salaryMax: null})
      }
    }
    dispatch({fromArray, toArray})
  }, [type, from])

  return (
    <div className="com-salary">
      <div className="type-wrap">
        <Select placeholder="请选择" value={type} options={state.typeList} onChange={changeType} />
      </div>
      {type > 0 && (
        <>
          <Select placeholder="请选择" value={from} options={state.fromArray} onChange={changeFrom} />
          <div className="gap">-</div>
          <Select placeholder="请选择" value={to} options={state.toArray} onChange={changeTo} />
        </>
      )}
    </div>
  )
}