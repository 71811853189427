import ajax from '../ajax/operateData'
import {useEffect, useState} from 'react'
import {replaceItemAtIndex, salaryChar, cityStr} from '../components'
import toast from '../components/toast'

export default function PosListModal({type, id, ids, close, onChange }) {
  const [list, setList] = useState([])

  useEffect(function() {
    ajax.getPosList(type, id).then(res => {
      if (res.success) {
        setList(res.data.map(item => ({
          ...item,
          cityList: item.cityVos,
          check: ids ? ids.includes(item.id) : false,
        })))
      }
    })
  }, [])

  function check(index) {
    const item = list[index]
    setList(replaceItemAtIndex(list, index, {...item, check: !item.check}))
  }
  function expand(index) {
    const item = list[index]
    setList(replaceItemAtIndex(list, index, {...item, expand: !item.expand}))
  }

  function confirm() {
    const temp = list.filter(item => item.check)
    // if (temp.length === 0) {
    //   toast.warn('请至少选择一个职位')
    //   return
    // }
    onChange(temp)
  }

  return (
    <div className="com-modal pos-list-modal">
      <div className="com-modal-wrap">
        <div className="modal-head">
          <div className="modal-title">{type === 'ep' ? '过往发过的职位' : '职位详情'}</div>
          <div className="close-wrap" onClick={close}>
            <div className="close-icon"></div>
          </div>
        </div>
        <div className="modal-content">
          {list.map((item, i) => (
            <div key={i} className="position-item">
              <div className={`position-head ${item.expand}`}>
                <div className="flex hand" onClick={() => check(i)}>
                  {type === 'ep' && <div className={`com-check ${item.check}`}></div>}
                  <div className="name-wrap f-600">{item.name} {salaryChar(item)}</div>
                </div>
                <div className="pro-wrap">{item.businessName} | {item.category}</div>
                <div className="city-wrap"><span className="c-75">城市：</span>{cityStr(item.city)}</div>
                {/*{type === 'ep' && (*/}
                {/*  <div className="edit-wrap">*/}
                {/*    <div className="edit-icon"></div>*/}
                {/*  </div>*/}
                {/*)}*/}
                <div className={`toggle-wrap ${item.expand ? 'up' : 'down'}`} onClick={() => expand(i)}></div>
              </div>
              <div className={`position-detail ${item.expand}`}>
                <div className="detail-wrap f-12">
                  <div className="f-600">职位详情</div>
                  <div className="detail">{item.description}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="modal-action">
          <div className="button block" onClick={confirm}>确认</div>
        </div>
      </div>
    </div>
  )
}