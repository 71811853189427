import {useRef} from 'react'
import toast from '../toast'
import {uploadFile} from '../oss'
import './avatar.scss'

const maxImageSize = 5 * 1024 * 1024

export default function Avatar({value, onChange}){
  const fileRef = useRef()
  const lastFile = useRef(null)

  function chooseFile(e) {
    if (e.target.files.length > 0) {
      const file = e.target.files[0]
      if (file.type.indexOf('image') === -1) {
        toast.warn('请上传图片文件')
        fileRef.current.value = ''
        return
      }
      if (file.size > maxImageSize) {
        toast.warn('图片大小超出限制')
        fileRef.current.value = ''
        return
      }
      uploadFile('account_avatar', file, lastFile.current).then(res => {
        lastFile.current = res.name
        let path = res.url.slice(0, res.url.indexOf(res.name))
        if (path.indexOf('http://') > -1) {
          path = path.replace('http://', 'https://')
        }
        onChange({path, url: res.name})
      })
    }
  }

  return (
    <div className="com-avatar" style={value ? {backgroundImage: `url(${value.path + value.url})`} : {}}>
      {value ? (
        <div className="tip">
          <div className="tip-icon"></div>
          修改
        </div>
      ) : <div className="empty"></div>}

      <input
        ref={fileRef}
        className="input-file"
        type="file"
        accept="image/*"
        onChange={chooseFile}
      />
    </div>
  )
}