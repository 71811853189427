import { useFastReducer } from '../hooks'
import toast from '../toast'
import { removeItemAtIndex } from '../index'
import ajax from '../../ajax/dict'
import './form.scss'
import { useEffect } from 'react'

export default function ChooseCity({ value = [], type = '', title = '', placeholder = '', maxLength = 20, single = false, onChange }) {
  const [state, dispatch] = useFastReducer({
    isModel: false,
    list: [],
    checkList: [],
  })
  const checkId = state.checkList.map(item => item?.id)

  function openPanel() {
    dispatch({ isModel: true })
  }
  function closePanel() {
    dispatch({ isModel: false })
  }

  function chooseItem(index) {
    if (state.checkList.length === maxLength) {
      toast.error(`最多可选${maxLength}个`)
      return
    }
    let item = state.list[index]
    //已选中 返回
    if (checkId.includes(item.id)) return
    //单选
    if (single) {
      dispatch({ checkList: [item] })
      return
    }
    //多选
    let checkList = [...state.checkList]
    checkList.push(item)
    dispatch({ checkList })
  }

  function removeItem(index) {
    dispatch({ checkList: removeItemAtIndex(state.checkList, index) })
  }

  function confirm() {
    if (state.checkList.length === 0) {
      toast.warn('请选择')
      return
    }
    closePanel()
    onChange(state.checkList)
  }

  //获取列表
  function getCityList() {
    ajax.getOperateCityList().then(res => {
      if (res.success) {
        let list = res.data.map(item => ({ id: item.id, name: item.cityName }))
        dispatch({ list })
      }
    })
  }
  function getAreaList() {
    ajax.getOpArea().then(res => {
      if (res.success) {
        let list = res.data.map(item => ({ id: item.id, name: item.areaName }))
        dispatch({ list })
      }
    })
  }
  function getAccountList() {
    ajax.getWXAccount().then(res => {
      if (res.success) {
        let list = res.data.map(item => ({ id: item.id, name: item.cityName + ' - ' + item.wechatNumber }))
        dispatch({ list })
      }
    })
  }

  useEffect(() => {
    if (state.isModel) {
      if (type === 'city') {
        getCityList()
        return
      }
      if (type === 'area') {
        getAreaList()
        return
      }
      getAccountList()
    }
  }, [state.isModel])

  useEffect(() => {
    dispatch({ checkList: value })
  }, [value])

  const str = value.map(item => item?.name).join('、')

  return (
    <div className='com-choose-city'>
      <div className="com-input with-arrow" onClick={openPanel}>
        {str ? <div className="input only-text">{str}</div> : <div className="input gray-text">{placeholder || '请选择'}</div>}
      </div>
      {state.isModel &&
        <div className='com-choose-panel'>
          <div className='panel-wrap'>
            <div className='title-wrap'>
              <div className='title'>{title || '选择'}</div>
              <div className='close' onClick={closePanel}></div>
            </div>
            <div className='content-wrap'>
              <div className='list-wrap'>
                {state.list && state.list.map((item, index) =>
                  <div className={`item ${checkId.includes(item.id)}`} key={index} onClick={() => chooseItem(index)}>{item.name}</div>
                )}
              </div>
            </div>
            {state.checkList.length > 0 &&
              <div className='check-wrap'>
                <div className='label'>已选：</div>
                <div className='check-box'>
                  {state.checkList.map((item, index) =>
                    <div className='item' key={index}>
                      {item.name}
                      <div className='del-icon' onClick={() => removeItem(index)}></div>
                    </div>
                  )}
                </div>
              </div>
            }
            <div className='button-wrap'>
              <div className='confirm' onClick={confirm}>确认</div>
            </div>
          </div>
        </div>
      }
    </div>

  )
}