import {useEffect, useRef} from 'react'

export default function ComparePanel({name, date = '', pre = 0, now = 0}) {
  const canvasRef = useRef()

  let color = '', unit = ''
  const gap = Math.round(now - pre)
  if (gap > 0 ) {
    color = 'green'
    unit = '+'
  }
  if (gap < 0 ) {
    color = 'red'
  }

  useEffect(() => {
    if (pre === 0 && now === 0) {
      return
    }
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')

    ctx.clearRect(0, 0, canvas.width, canvas.height)

    // 画柱子
    const tall = Math.max(pre, now)
    const height1 = Math.round(pre * 68 / tall)
    const y1 = Math.round(canvas.height - height1)
    ctx.fillStyle = '#DDDCFA'
    ctx.fillRect(16, y1, 14, height1)
    const height2 = Math.round(now * 68 / tall)
    const y2 = Math.round(canvas.height - height2)
    ctx.fillStyle = '#7D79EC'
    ctx.fillRect(52, y2, 14, height2)

    // 画虚线
    ctx.strokeStyle = '#999'
    ctx.beginPath()
    ctx.moveTo(23, y1)
    ctx.lineTo(59, y2)
    ctx.setLineDash([1, 2])
    ctx.stroke()
    ctx.closePath()

    // 画数字
    ctx.fillStyle = '#254669'
    ctx.font = 'bold 12px sans-serif'
    const preLen = ctx.measureText(pre).width
    const nowLen = ctx.measureText(now).width
    ctx.fillText(pre, Math.round(23 - (preLen / 2)), Math.round(canvas.height - height1 - 4))
    ctx.fillText(now, Math.round(59 - (nowLen / 2)), Math.round(canvas.height - height2 - 4))
  }, [pre, now])

  return (
    <div className="compare-panel flex align">
      <div className="left">
        <div className="title">
          <span className="f-600">{name}</span><span className="gray">（{date}）</span>
        </div>
        <div className="number-wrap">
          <span className="number heavy f-600">{pre}</span>
          <span className="f-12 unit">个</span>
        </div>
        <div className="trend f-12">
          净增：
          <span className={'f-600 ' + color}>{unit}{gap}</span>
        </div>
      </div>
      <canvas className="compare-img" ref={canvasRef} width="82" height="84"></canvas>
    </div>
  )
}