import { createRoot } from 'react-dom/client'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import {env} from './components'

import Layout from './common/Layout'
import NoMatch from './common/NoMatch'
import Dashboard from './dashboard/index'
import Team from './dashboard/Team'
import Transform from './dashboard/Transform'
import Progress from './dashboard/Progress'

import AccountList from './account/AccountList'
import AccountForm from './account/AccountForm'

import CityList from './dict/CityList'
import AreaList from './dict/AreaList'
import GroupList from './dict/GroupList'
import WxList from './dict/WxList'

import OperateList from './operateData/OperateList'
import OperateForm from './operateData/OperateForm'
import ImageTable from './operateData/ImageTable'

import {ConfigProvider} from 'antd'
import zhCN from 'antd/locale/zh_CN'
import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import 'dayjs/locale/zh-cn'
import './app.scss'

dayjs.locale('zh-cn')
dayjs.extend(quarterOfYear)

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {index: true, element: <Dashboard />},
      {path: 'team', element: <Team />},
      {path: 'transform', element: <Transform />},
      {path: 'progress', element: <Progress />},
      {path: 'account', children: [
        {path: 'list', element: <AccountList />},
        {path: 'form', element: <AccountForm />},
        {path: 'form/:id', element: <AccountForm />},
      ]},
      {path: 'dict', children: [
        {path: 'city', element: <CityList />},
        {path: 'area', element: <AreaList />},
        {path: 'group', element: <GroupList />},
        {path: 'wx', element: <WxList />},
      ]},
      {path: 'operate-data', children: [
        {path: 'list', element: <OperateList />},
        {path: 'form', element: <OperateForm />},
        {path: 'form/:id', element: <OperateForm />},
        {path: 'img-table', element: <ImageTable />},
      ]},
      {path: '*', element: <NoMatch />},
    ],
  },
], {basename: env === 'test' ? '/operate' : '/'})

createRoot(document.getElementById('app')).render(
  <ConfigProvider locale={zhCN}>
    <RouterProvider router={router} />
  </ConfigProvider>
)
