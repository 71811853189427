import Breadcrumb from '../common/Breadcrumb'
import { useFastReducer } from '../components/hooks'
import showConfirm from '../components/confirm'
import { useEffect } from 'react'
import ajax from '../ajax/dict'
import { Pagination } from 'antd'
import toast from '../components/toast'
import Input from '../components/form/Input'
import './dict.scss'

export default function WxList() {
  const [state, dispatch] = useFastReducer({
    page: 1,
    size: 15,
    isModel: false,
    list: null,
    item: null, // 当前编辑项
  })

  //新增
  function add() {
    dispatch({ isModel: true, item: null })
  }
  //编辑
  function edit(item) {
    dispatch({ isModel: true, item })
  }
  function closeModel() {
    dispatch({ isModel: false })
  }

  //删除
  function delItem(id) {
    showConfirm(
      {
        title: '提示',
        msg: '是否确认删除该微信账号？',
      },
      () => {
        ajax.deleteWX(id).then(res => {
          if (res.success) {
            toast.success('删除成功')
            getList()
          }
        })
      },
    )
  }

  //获取列表
  function getList() {
    const params = {
      pageNum: state.page,
      pageSize: state.size,
    }
    ajax.getWXList(params).then(res => {
      if (res.success) {
        dispatch({
          list: res.data.records,
          total: res.data.total,
        })
      }
    })
  }

  // 分页切换
  function pageChange(page) {
    dispatch({page})
  }

  useEffect(() => {
    getList()
  }, [state.page])

  function refresh() {
    if (state.item) {
      getList()
    } else {
      if (state.page === 1) {
        getList()
      } else {
        dispatch({page: 1})
      }
    }
  }

  return (
    <div className="com-content dict-list">
      <div className="com-fix-wrap">
        <div className="flex align between">
          <Breadcrumb path="/dict/wx" />
          <div className="plus-button" onClick={add}>新增微信账号</div>
        </div>
        <div className='table-wrap'>
          <div className='table-head'>
            <div className='col-start'>微信账号</div>
            <div className='col-21'>微信昵称</div>
            <div className='col-21'>运营城市</div>
            <div className='col-grow'>创建时间</div>
            <div className='col-end'>操作</div>
          </div>
          <div className='table-content'>
            {state.list && state.list.map((item, index) =>
              <div className='row' key={index}>
                <div className='col-start'>{item.wechatNumber}</div>
                <div className='col-21'>{item.wechatName}</div>
                <div className='col-21'>{item.cityName}</div>
                <div className='col-grow'>{item.createTime}</div>
                <div className='col-end'>
                  <div className='del-btn' onClick={() => delItem(item.id)}><div className='del-icon' />删除</div>
                  <div className='edit-btn' onClick={() => edit(item)}><div className='edit-icon' />编辑</div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='pagination'>
          <Pagination align="end" showQuickJumper current={state.current} pageSize={state.size} total={state.total} onChange={pageChange} />
        </div>
      </div>
      {state.isModel && <EditModel close={closeModel} item={state.item} refresh={refresh} />}
    </div>
  )
}


function EditModel({ close, item, refresh }) {
  const [state, dispatch] = useFastReducer({
    name: item ? item.wechatName: '',
    account: item ? item.wechatNumber : '',
    cityId: item ? item.cityId : null,
    city: item ? { cityId: item.cityId, cityName: item.cityName } : {},
    isModel: false,
    cityList: [],
  })

  useEffect(function () {
    ajax.getOperateCityList().then(res => {
      if (res.success) {
        dispatch({ cityList: res.data })
      }
    })
  }, [])

  function confirm() {
    if (!state.account) {
      toast.warn('请填写微信账号')
      return
    }
    if (!state.name) {
      toast.warn('请填写微信昵称')
      return
    }
    if (!state.city.cityName) {
      toast.warn('请选择运营城市')
      return
    }
    if (item) {
      //编辑
      const params = {
        id: item.id,
        wechatNumber: state.account,
        wechatName: state.name,
        cityId: state.city.cityId,
        cityName: state.city.cityName,
      }
      ajax.editWX(params).then(res => {
        if (res.success) {
          toast.success('修改成功')
          close()
          refresh()
        }
      })
    } else {
      //新增
      const params = {
        wechatNumber: state.account,
        wechatName: state.name,
        cityId: state.city.cityId,
        cityName: state.city.cityName,
      }
      ajax.addWX(params).then(res => {
        if (res.success) {
          toast.success('新增成功')
          close()
          refresh()
        }
      })
    }
  }

  //城市
  function openCity() {
    dispatch({ isModel: true })
  }
  function closeCity() {
    dispatch({ isModel: false })
  }
  function confirmCity() {
    let city = state.cityList.find(item => item.id === state.cityId)
    dispatch({ city: { cityId: city.id, cityName: city.cityName }, isModel: false })
  }
  function checkItem(id) {
    dispatch({ cityId: id })
  }

  return (
    <div className='dict-model'>
      {!state.isModel &&
        <div className='wx-wrap wid'>
          <div className='title-wrap'>
            <div className='title'>新增微信账号</div>
            <div className='close' onClick={close}></div>
          </div>
          <div className='content-wrap'>
            <div className="flex between">
              <div className='input-wrap'>
                <div className='label'>微信账号：</div>
                <Input
                  placeholder='请输入微信账号'
                  value={state.account}
                  onChange={(v) => dispatch({ account: v })}
                />
              </div>
              <div className='input-wrap'>
                <div className='label'>微信昵称：</div>
                <Input
                  placeholder='请输入微信昵称'
                  value={state.name}
                  onChange={(v) => dispatch({ name: v })}
                />
              </div>
            </div>
            <div className='input-wrap'>
              <div className='label'>运营城市：</div>
              <div className={`select ${!!state.city.cityName}`} onClick={openCity}>{state.city.cityName || '请选择'}</div>
            </div>
          </div>
          <div className='button-wrap'>
            <div className='confirm' onClick={confirm}>确认</div>
          </div>
        </div>
      }
      {state.isModel &&
        <div className='select-city'>
          <div className='title-wrap'>
            <div className='title'>请选择运营城市</div>
            <div className='close' onClick={closeCity}></div>
          </div>
          <div className='content-wrap'>
            <div className='city-box'>
              {state.cityList && state.cityList.map((item, index) =>
                <div
                  className={`item ${state.cityId === item.id}`}
                  key={index}
                  onClick={() => checkItem(item.id)}
                >
                  {item.cityName}
                </div>
              )}
            </div>
          </div>

          <div className='button-wrap'>
            <div className='confirm' onClick={confirmCity}>确认</div>
          </div>
        </div>
      }
    </div>
  )
}