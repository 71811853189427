import { createPortal } from 'react-dom'
import { createRoot } from 'react-dom/client'
import './confirm.scss'

const body = document.querySelector('body')
const confirmEl = document.createElement('div')
confirmEl.id = 'confirm-root'
let flag = false // 同一时间只能有一个confirm
let confirmRoot // react dom root

function Confirm({ config = {}, callback, cancel }) {
    function confirm() {
        callback && callback()
        close()
    }
    function cancelConfirm() {
        cancel && cancel()
        close()
    }
    return createPortal(
        <div className="com-confirm">
            <div className="confirm-modal">
                <div className='title-wrap'>
                    <div className='title'>{config.title}</div>
                    <div className='close' onClick={close}></div>
                </div>
                <div className="content">
                    <div className="message">{config.msg}</div>
                    <div className="sub-message">{config.desc}</div>
                </div>
                <div className="action">
                    {!config.noClose && (
                        <div
                            className="button"
                            onClick={cancelConfirm}
                        >
                            {config.closeText || '取消'}
                        </div>
                    )}
                    <div className="button contained" onClick={confirm}>
                        {config.confirmText || '确定'}
                    </div>
                </div>
            </div>
        </div>,
        confirmEl
    )
}

export default function open(config, callback, cancel) {
    if (flag) return
    flag = true
    const outerWidth = window.innerWidth
    const innerWidth = body.clientWidth
    if (outerWidth > innerWidth) {
        const gap = outerWidth - innerWidth + 'px'
        body.style.paddingRight = gap
    }
    body.style.overflow = 'hidden'
    body.appendChild(confirmEl)
    confirmRoot = createRoot(confirmEl)
    confirmRoot.render(
        <Confirm config={config} callback={callback} cancel={cancel} />
    )
}

function close() {
    flag = false
    body.style.overflow = ''
    body.style.paddingRight = ''
    confirmRoot.unmount()
    body.removeChild(confirmEl)
}
