import {useFastReducer} from '../components/hooks'
import Input from '../components/form/Input'
import toast from '../components/toast'
import {useEffect} from 'react'

export default function HRForm({close, hr, onChange}) {
  const [state, dispatch] = useFastReducer({
    id: undefined,
    name: '',
    job: '',
    leaderJob: '',
    phone: '',
    email: '',
  })

  function add() {
    if (!state.name) {
      toast.warn('请输入姓名')
      return
    }
    // if (!state.job) {
    //   toast.warn('请输入职务')
    //   return
    // }
    // if (!state.leaderJob) {
    //   toast.warn('请输入直属领导职务')
    //   return
    // }
    if (state.phone && state.phone.length !== 11) {
      toast.warn('请输入正确的手机号')
      return
    }
    // if (!state.email) {
    //   toast.warn('请输入邮箱')
    //   return
    // }
    onChange(state)
  }

  useEffect(() => {
    if (hr) {
      dispatch(hr)
    }
  }, [hr])

  return (
    <div className="com-modal hr-form">
      <div className="com-modal-wrap">
        <div className="modal-head">
          <div className="modal-title">{hr ? '编辑' : '新增'}HR信息</div>
          <div className="close-wrap" onClick={close}>
            <div className="close-icon"></div>
          </div>
        </div>
        <div className="modal-content">
          <div className="flex">
            <div className="input-group">
              <div className="label required">姓名：</div>
              <Input placeholder="请输入" value={state.name} onChange={v => dispatch({name: v})} />
            </div>
            <div className="input-group">
              <div className="label">职务：</div>
              <Input placeholder="请输入" value={state.job} onChange={v => dispatch({job: v})} />
            </div>
            <div className="input-group">
              <div className="label">直属领导职务：</div>
              <Input placeholder="请输入" value={state.leaderJob} onChange={v => dispatch({leaderJob: v})} />
            </div>
          </div>
          <div className="flex">
            <div className="input-group">
              <div className="label">手机：</div>
              <Input type="number" placeholder="请输入" value={state.phone} onChange={v => dispatch({phone: v})} />
            </div>
            <div className="input-group email">
              <div className="label">邮箱：</div>
              <Input placeholder="请输入" value={state.email} onChange={v => dispatch({email: v})} />
            </div>
          </div>
        </div>
        <div className="modal-action">
          <div className="button block" onClick={add}>确认{hr ? '修改' : '新增'}</div>
        </div>
      </div>
    </div>
  )
}