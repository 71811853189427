export default function Breadcrumb({path, id}) {
  let name1 = '首页', name2 = ''
  const [,first, second, third] = path.split('/')

  if (first === 'account') {
    name1 = '账号管理'
    if (second === 'form') {
      name2 = id ? '编辑账号' : '创建账号'
    }
  }
  if (first === 'dict') {
    name1 = '字典'
    if (second === 'city') {
      name2 = '运营城市'
    }
    if (second === 'area') {
      name2 = '所属区域'
    }
    if (second === 'group') {
      name2 = '所属小组'
    }
    if (second === 'wx') {
      name2 = '微信账号'
    }
  }

  if (first === 'operate-data') {
    name1 = '运营数据'
    if (second === 'form') {
      name2 = id ? '编辑表单': '创建表单'
    }
  }

  return (
    <div className="breadcrumb-wrap">
      <div>{name1}</div>
      {name2 && (
        <div className="flex align">
          <div className="gap">-</div>
          <div className="light">{name2}</div>
        </div>
      )}
    </div>
  )
}