import { useFastReducer } from '../components/hooks'
import dayjs from 'dayjs'
import { DatePicker } from 'antd'
import './transform.scss'
import { useEffect } from 'react'
import ajax from '../ajax/dashboard'

const weekArray = ['一', '二', '三', '四', '五']

export default function Transform() {
  const [state, dispatch] = useFastReducer({
    date: dayjs().subtract(1, 'day'),
    compare: 0, // 0 同期对比 1 连续对比
    list: null,
  })

  function disabledDate(current) {
    return current && current > dayjs().endOf('day')
  }

  useEffect(() => {
    const params = {
      date: state.date.startOf('month').format('YYYY-MM-DD'),
      isSamePeriod: state.compare,
    }
    ajax.getTransformData(params).then(res => {
      if (res.success) {
        dispatch({ list: res.data })
      }
    })
  }, [state.date, state.compare])

  return (
    <div className="com-content flex">
      <div className="transform-page">
        <div className="head-title flex align between">
          <div className="f-600">转化数据看板</div>
          <div className="flex">
            <div className="compare-box f-12 flex">
              <div className={`compare ${state.compare === 0}`} onClick={() => dispatch({compare: 0})}>同期对比</div>
              <div className={`compare ${state.compare === 1}`} onClick={() => dispatch({compare: 1})}>连续对比</div>
            </div>
            <DatePicker picker="month" value={state.date} disabledDate={disabledDate} onChange={date => dispatch({ date })} allowClear={false} />
          </div>
        </div>
        <div className='content-wrap'>
          {state.list && state.list.length > 0 &&
          <div className='table-wrap'>
            <div className="transform-table left-head">
              <div className='table-head'>
                <div className='user center'>责任人</div>
                <div className='user center'>城市</div>
              </div>
              <div className='table-body'>
                {state.list.map((item, index) =>
                (index < state.list.length - 1 ?
                  <div className='area-wrap' key={index} style={{ 'background': '#' + item[0].color }}>
                    {item.map((itm, idx) =>
                      <div className='user-wrap flex' key={idx}>
                        <div className='user center'>{itm.username}</div>
                        <div className='city-wrap'>
                          {itm.cityList.map((it, i) => <div className='flex' key={i}>
                            <div className='city center'>{it.cityName}</div>
                          </div>)}
                        </div>
                      </div>
                    )}
                  </div>
                  :
                  <div className='all-wrap' key={index}>
                    <div className='label center'>团队完成总计</div>
                  </div>)
                )}
              </div>
            </div>
            <div className="transform-table">
              <div className='table-head'>
                {/* <div className='user center'>责任人</div> */}
                {/* <div className='user center'>城市</div> */}
                {state.list[0][0].cityList[0].transFormDayDataList.map((item, index) =>
                  <div className='week' key={index}>
                    <div className='week-name center'>第{weekArray[index]}周</div>
                    <div className='flex align'>
                      {item.map((it, i) => <div className={`week-${i === item.length - 1 ? 'end' : 'day'} center`} key={i}>{it.date}</div>)}
                    </div>
                  </div>
                )}
                <div className='total center'>完成合计</div>
                <div className='target center'>总目标</div>
                <div className='percent center'>达成比</div>
                <div className='average center'>日均</div>
                {state.list[0][0].cityList[0].monthCountVoList.map((item, index) =>
                  <div className='month' key={index}>
                    <div className='month-name center'>{item.month}</div>
                    <div className='flex align'>
                      <div className='month-day center'>总计</div>
                      <div className='month-day center'>日均</div>
                      <div className='month-trend center'>成长趋势</div>
                    </div>
                  </div>
                )}
              </div>
              <div className='table-body'>
                {state.list.map((item, index) =>
                (index < state.list.length - 1 ?
                  <div className='area-wrap' key={index} style={{ 'background': '#' + item[0].color }}>
                    {item.map((itm, idx) =>
                      <div className='user-wrap flex' key={idx}>
                        {/* <div className='user center'>{itm.username}</div> */}
                        <div className='city-wrap'>
                          {itm.cityList.map((it, i) => <div className='flex' key={i}>
                            {/* <div className='user center'>{it.cityName}</div> */}
                            {it.transFormDayDataList.map((week, wi) =>
                              <div className='flex' key={wi}>
                                {week.map((day, di) =>
                                  <div
                                    className={
                                      `week-${di === week.length - 1 ? 'end' : 'day'} 
                                      ${day.flag === 1 ? 'red' : (day.flag === 2 ? 'green' : '')} 
                                      center`
                                    }
                                    key={di}
                                  >
                                    {day.count}
                                  </div>)}
                              </div>
                            )}
                            <div className='total center'>{it.complete}</div>
                            <div className='target center'>{it.target}</div>
                            <div className='percent center'>{it.percent}</div>
                            <div className='average center red'>{it.dayCount}</div>
                            {it.monthCountVoList.map((mon, mi) =>
                              <div className='flex' key={mi}>
                                <div className='month-day center'>{mon.monthCount}</div>
                                <div className='month-day center red'>{mon.monthDayCount}</div>
                                <div className='month-trend center'>
                                  {mon.monthTrend > 1 && <div className='icon-up' />}
                                  {mon.monthTrend < 0 && <div className='icon-down' />}
                                  {(mon.monthTrend >= 0 && mon.monthTrend <= 1) && '- -'}
                                </div>
                              </div>
                            )}
                          </div>)}
                        </div>
                      </div>
                    )}
                  </div>
                  :
                  <div className='all-wrap' key={index}>
                    {/* <div className='label center'>团队完成总计</div> */}
                    {item[0].cityList[0].transFormDayDataList.map((week, wi) =>
                      <div className='flex' key={wi}>
                        {week.map((day, di) =>
                          <div className={`week-${di === week.length - 1 ? 'end' : 'day'} center`} key={di}>{day.count}</div>
                        )}
                      </div>
                    )}
                    <div className='total center f-14'>{item[0].cityList[0].complete}</div>
                    <div className='target center f-14'>{item[0].cityList[0].target}</div>
                    <div className='percent center'>{item[0].cityList[0].percent}</div>
                    <div className='average center'>{item[0].cityList[0].dayCount}</div>
                    {item[0].cityList[0].monthCountVoList?.map((mon, mi) =>
                      <div className='flex' key={mi}>
                        <div className='month-day center f-14'>{mon.monthCount}</div>
                        <div className='month-day center f-14'>{mon.monthDayCount}</div>
                        <div className='month-trend center'>/</div>
                      </div>
                    )}
                  </div>)
                )}
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  )
}