import commonAjax from '../../ajax/common'
import {useEffect} from 'react'
import {useFastReducer} from '../hooks'

let abCache

export default function ChooseAb({value, placeholder, onChange}) {
  const [state, dispatch] = useFastReducer({
    is: false,
    abList: [],
    first: 0,
    second: -1,
    third: -1,
  })

  useEffect(function () {
    if (abCache) {
      dispatch({abList: abCache})
    } else {
      commonAjax.getBusinessCate(1).then(res => {
        if (res.success) {
          let abList = res.data[1] || []
          abCache = abList
          dispatch({abList})
        }
      })
    }
  }, [])

  let temp = state.abList[state.first]
  const second = temp ? temp.next : []
  temp = second[state.second]
  const third = temp ? temp.next : []
  const current = third[state.third]

  function open() {
    dispatch({is: true})
  }
  function close() {
    dispatch({is: false})
  }
  function confirm() {
    if (current) {
      close()
      onChange(current)
    }
  }

  return (
    <div className="com-input with-arrow com-choose-ab">
      {value ? <div className="input only-text" onClick={open}>{value}</div>
        : <div className="input gray-text" onClick={open}>{placeholder}</div>}
      {state.is && (
        <div className="com-modal ab-form">
          <div className="com-modal-wrap">
            <div className="modal-head">
              <div className="modal-title">请选择职能</div>
              <div className="close-wrap" onClick={close}>
                <div className="close-icon"></div>
              </div>
            </div>
            <div className="modal-content">
              <div className="flex">
                <div className="left-wrap">
                  <div className="title f-600">行业</div>
                  <div className="menu-wrap">
                    {state.abList.map((item, i) => (
                      <div key={i} className={`ab-item ${state.first === i}`} onClick={() => dispatch({first: i, second: -1, third: -1})}>
                        {item.name}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="right-wrap">
                  <div className="title f-600">职能大类</div>
                  <div className="flex wrap">
                    {second.map((item, i) => ((
                      <div key={i} className={`ab-item ${state.second === i}`} onClick={() => dispatch({second: i, third: -1})}>
                        {item.name}
                      </div>
                    )))}
                  </div>
                  <div className="gray-bg">
                    <div className="title f-600">职能</div>
                    {state.second === -1 && <div className="tip f-12">请选择上方职能大类</div>}
                    <div className="flex wrap">
                      {third.map((item, i) => ((
                        <div key={i} className={`ab-item ${state.third === i}`} onClick={() => dispatch({third: i})}>
                          {item.name}
                        </div>
                      )))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="select-wrap flex align">
              <div className="f-600">当前选中：</div>
              {current && <div className="ab-item">{current.name}</div>}
            </div>
            <div className="modal-action">
              <div className={`button block ${state.third > -1}`} onClick={confirm}>确认</div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
