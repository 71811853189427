import { useLocation, useNavigate } from 'react-router-dom'
import './accountList.scss'
import Breadcrumb from '../common/Breadcrumb'
import { Link } from 'react-router-dom'
import { useFastReducer } from '../components/hooks'
import { useEffect } from 'react'
import ajax from '../ajax/account'
import { Pagination } from 'antd'
import toast from '../components/toast'
import showConfirm from '../components/confirm'
import {defaultHead} from '../components'

export default function AccountList() {
  const navigate = useNavigate()
  const hash = useLocation().pathname
  const [state, dispatch] = useFastReducer({
    page: 1,
    size: 15,
    list: null,
    sexArr: ['女', '男', '其他'],
    isModel: false,
    goalModel: 0,
  })

  //获取列表
  function getList() {
    const params = {
      page: state.page,
      size: state.size
    }
    ajax.getAccountList(params).then(res => {
      if (res.success) {
        dispatch({
          list: res.data.records,
          current: res.data.current,
          total: res.data.total,
        })
      }
    })
  }

  // 分页切换
  function pageChange(page) {
    if (page === state.page) return
    dispatch({ page })
  }

  //运营账号展示弹窗
  function showMore(data) {
    dispatch({ accountData: data, isModel: true })
  }
  function closeModel() {
    dispatch({ isModel: false })
  }

  //更多目标
  function showMoreGoal(e, index) {
    e.stopPropagation()
    dispatch({ goalModel: index + 1 })
    document.body.onclick = closeMoreGoal
  }
  function closeMoreGoal() {
    dispatch({ goalModel: 0 })
    document.body.onclick = null
  }

  //停用账号
  function changeAccountState(item) {
    const params = {
      isDisable: item.isDisable === 0 ? 1 : 0, //是否禁用 1 是 0 否
      userId: item.id
    }
    showConfirm(
      {
        title: '提示',
        msg: `是否确认${item.isDisable === 0 ? '停用' : '启用'}该账号？`,
      },
      () => {
        ajax.disableAccount(params).then(res => {
          if (res.success) {
            toast.success(`${item.isDisable === 0 ? '停用' : '启用'}成功`)
            getList()
          }
        })
      },
    )
  }

  //编辑
  function goToEdit(id) {
    navigate('/account/form/' + id)
  }

  useEffect(() => {
    getList()
  }, [state.page])

  return (
    <div className="com-content account-content">
      <div className="com-fix-wrap">
        <div className="title-wrap">
          <Breadcrumb path={hash} />
          <Link to="../form" className="plus-button">创建账号</Link>
        </div>
        {state.list && state.list.map((item, index) =>
          <div key={index} className='list-card' >
            {/*<div className='group-name'>{item.groupName || '--'}</div>*/}
            <div className='info-wrap'>
              <div className='wrap-1'>
                <div className='left'>
                  <div className='user-wrap'>
                    <div className='head-img' style={{ backgroundImage: `url(${item.headUrl ? item.path + item.headUrl : defaultHead})` }} />
                    <div className='username'>{item.username}</div>
                    <div className='line'></div>
                    {Array.from({ length: item.starTarget }).map((it, i) => <div key={i} className='star'></div>)}
                    <div className='goal-wrap'>
                      {item.cityTargetNumVos?.slice(0, 2).map((it, i) =>
                        <div className='flex align' key={i}>
                          {i > 0 && <div className='line org'></div>}
                          <div className='goal'>{it.cityName}月目标：<div className='num'>{it.target}</div></div>
                        </div>
                      )}
                      {item.cityTargetNumVos?.length > 2 && <div className='more ml-10' onClick={(e) => showMoreGoal(e, index)}>更多</div>}
                      {(state.goalModel === index + 1) && <div className='more-goal' onClick={e => e.stopPropagation()}>
                        <div className='f-600'>月目标：</div>
                        {item.cityTargetNumVos.map((it, i) =>
                          <div className='flex align' key={i}>
                            {i > 0 && <div className='it-line'></div>}
                            <div className='it'>{it.cityName}·<div className='f-600'>{it.target}</div></div>
                          </div>
                        )}
                      </div>}
                    </div>
                  </div>
                  <div className='sex'>{state.sexArr[item.sex]}｜{item.age}岁</div>
                  <div className='tel'>TEL：{item.phone}</div>
                </div>
                <div className='right'>
                  <div className='time'>创建：{item.createTime}</div>
                  <div className='flex align'>
                    {item.isDisable === 0 && <div className='stop-btn' onClick={() => changeAccountState(item)}><div className='stop-icon' />停用</div>}
                    {item.isDisable === 1 && <div className='start-btn' onClick={() => changeAccountState(item)}><div className='start-icon' />启用</div>}
                    <div className='edit-btn' onClick={() => goToEdit(item.id)}><div className='edit-icon' />编辑</div>
                  </div>
                </div>
              </div>
              <div className='wrap-2'>
                <div className='col-1'>运营账号</div>
                <div className='col-2'>所属区域</div>
                <div className='col-3'>运营城市</div>
                <div className='col-4'>辐射城市</div>
                <div className='col-5'>关联城市</div>
              </div>
              <div className='wrap-3'>
                <div className='col-1 flex align'>
                  {item.accountList && item.accountList.slice(0, 2).map((item, index) =>
                    <div className='account' key={index}><span className='f-600'>{item.cityName}</span> - <span>{item.wechatNumber}</span></div>
                  )}
                  {item.accountList && item.accountList.length > 2 && <div className='more' onClick={() => showMore(item.accountList)}>更多</div>}
                </div>
                <div className='col-2'>
                  <div className='info'>{item.areaName}</div>
                </div>
                <div className='col-3'>
                  <div className='info'>{item.operateCity}</div>
                </div>
                <div className='col-4'>
                  <div className='info'>{item.radiationCity}</div>
                </div>
                <div className='col-5'>
                  <div className='info'>{item.relevanceCity}</div>
                </div>
              </div>
            </div>
          </div>
        )}
        {state.list && (
          <div className='pagination'>
            <Pagination align="end" showQuickJumper current={state.current} pageSize={state.size} total={state.total} onChange={pageChange} />
          </div>
        )}
        {state.isModel && <AccountModel data={state.accountData} close={closeModel} />}
      </div>
    </div>
  )
}

function AccountModel({ data, close }) {
  return (
    <div className='account-model'>
      <div className='account-wrap'>
        <div className='title-wrap'>
          <div className='title'>运营账号</div>
          <div className='close' onClick={close}></div>
        </div>
        <div className='content-wrap'>
          {data && data.map((item, index) => <div className='item' key={index}>{item.cityName} - {item.wechatNumber}</div>)}
        </div>
      </div>
    </div>
  )
}