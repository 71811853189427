import TableCanvas from './TableCanvas'
import './imageTable.scss'

export default function ImageTable() {
  return (
    <div className="com-content">
      <div className="image-table">
        <TableCanvas date={'2024-09-02'} />
      </div>
    </div>
  )
}