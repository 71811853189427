import {useEffect, useState} from 'react'
import './associateInput.scss'

export default function AssociateInput({name, placeholder = '请输入', value, list, onChange, dispatch, select}) {
  const [focus, setFocus] = useState(false)

  function on() {
    setFocus(true)
    onChange(value)
  }
  function off() {
    setFocus(false)
  }
  function change(e) {
    onChange(e.target.value.trim())
  }
  // click away listener
  function clickAway(e) {
    const temp = document.querySelector('#' + name)
    if (!temp.contains(e.target)) {
      dispatch({[name + 'List']: null})
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', clickAway)
    return () => {
      document.body.removeEventListener('click', clickAway)
    }
  }, [])

  return (
    <div id={name} className="com-ass-input">
      <div className={`com-input with-arrow ${focus}`}>
        <input
          type="text"
          autoComplete="off"
          className="input"
          placeholder={placeholder}
          value={value}
          onFocus={on}
          onBlur={off}
          onChange={change}
        />
      </div>
      {list && (
        <div className="ass-pop">
          {list.map((item, i) => (
            <div key={i} className="pop-item" onClick={() => select(item)}>{item.enterpriseName}-{item.city}</div>
          ))}
        </div>
      )}
    </div>
  )
}