import {Link} from 'react-router-dom'
import './layout.scss'

export default function NoMatch() {

  return (
    <div className="com-content no-match">
      <div className="no-match-text f-16">迷路了?</div>
      <Link className="back-btn" to="/dashboard" replace={true}>回到首页</Link>
    </div>
  )
}