import { useFastReducer } from '../components/hooks'
import dayjs from 'dayjs'
import { DatePicker } from 'antd'
import './progress.scss'
import { useEffect } from 'react'
import ajax from '../ajax/dashboard'


const star = ['', '一', '二', '三', '四', '五']

export default function Transform() {
  const [state, dispatch] = useFastReducer({
    date: dayjs().subtract(1, 'day'),
    list: [],
  })

  function disabledDate(current) {
    return current && current > dayjs().endOf('day')
  }

  useEffect(() => {
    const params = {
      date: state.date.startOf('month').format('YYYY-MM-DD')
    }
    ajax.getDataProgress(params).then(res => {
      if (res.success) {
        dispatch({
          list: res.data.userVoList,
          alreadyDay: res.data.alreadyDay,
          residueDay: res.data.residueDay,
          time: res.data.date,
          month: dayjs(res.data.date).month() + 1,
          progress: (res.data.alreadyDay / (res.data.alreadyDay + res.data.residueDay)) * 100
        })
      }
    })
  }, [state.date])

  return (
    <div className="com-content flex center">
      <div className="progress-page">
        <div className="head-title flex align between">
          <div className="f-600">数据进度看板</div>
          <DatePicker picker="month" value={state.date} disabledDate={disabledDate} onChange={date => dispatch({ date })} allowClear={false} />
        </div>
        <div className='content-wrap'>
          <div className='info flex'>
            <div className='info-left'>
              <div className='month'>{state.month}月工作日·{state.alreadyDay + state.residueDay}天</div>
              <div className='day-wrap'>
                <div>已过·{state.alreadyDay}天</div>
                <div className='residue'><span className='black'>剩余</span>·<span className='red'>{state.residueDay}天</span></div>
              </div>
              <div className='progress'>
                <div className='used' style={{ width: `${state.progress}%` }}></div>
              </div>
            </div>
            <div className='info-right'>
              <div>当前数据提点进度</div>
              <div className='time'>{state.time}</div>
              <div className='desc'>剩余目标拆解单位：个/天</div>
            </div>
          </div>
          <div className='table'>
            <div className='table-head'>
              <div className='col-1 center'>责任人</div>
              <div className='col-2 center'>城市</div>
              <div className='col-3 center'>个人进度</div>
              <div className='col-4 center'>个人月进度</div>
              <div className='col-5 center'>城市目标</div>
              <div className='col-6 center'>60%以上 <span className='desc'>提点为0.05</span></div>
              <div className='col-7 center'>70%以上 <span className='desc'>提点为0.1</span></div>
              <div className='col-8 center'>80%以上 <span className='desc'>提点为0.15</span></div>
              <div className='col-9 center'>90%以上 <span className='desc'>提点为0.2</span></div>
              <div className='col-10 center'>100%完成目标</div>
              <div className='col-11 center'>升星目标</div>
              <div className='col-12 center'>日基操范围</div>
            </div>
            {state.list && state.list.length > 0 &&
              <div className='table-body'>
                {state.list.map((item, index) =>
                  <div className='area' key={index} style={{ 'background': item[0].color }}>
                    {item.map((user, userIndex) =>
                      <div className='user' key={userIndex}>
                        <div className='col-1 center'>{user.username}</div>
                        <div className='city-wrap'>
                          {user.cityList.map((it, i) =>
                            <div className='flex' key={i}>
                              <div className='col-2'>
                                <div className='city-name'>{it.cityName}</div>
                                {it.starLevel > 0 &&
                                  <>
                                    <div className='star'>·{star[it.starLevel]}</div>
                                    <div className='star-icon'></div>
                                  </>
                                }
                              </div>
                              <div className='col-3 center'>{it.scheduleNum}</div>
                              <div className='col-4'>
                                <div className='percent' style={{ 'width': it.schedulePercent + '%' ,'background':user.color2}}></div>
                                <div className='num'>{it.schedulePercent}%</div>
                              </div>
                              <div className='col-5 center'>{it.target}</div>
                              <div className='col-6 center'>{it.target6}</div>
                              <div className='col-7 center'>{it.target7}</div>
                              <div className='col-8 center'>{it.target8}</div>
                              <div className='col-9 center'>{it.target9}</div>
                              <div className='col-10 center'>{it.targetAll}</div>
                              <div className='col-11 center red'>{it.upStar||'-'}</div>
                              <div className='col-12 center'>{it.dayScope}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}