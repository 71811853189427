import {useState} from 'react'
import {Outlet, useNavigate, useLocation} from 'react-router-dom'
import {user, defaultHead, setUser} from '../components'
import Login from './Login'
import {Popover} from 'antd'
import ajax from '../ajax/common'
import './layout.scss'

const routeName = {
  index: '关键数据看板',
  team: '团队数据看板',
  transform: '转化数据看板',
  progress: '数据进度看板',
  account: '账号管理',
  'operate-data': '运营数据',
}

const dictRoute = {
  name: '字典',
  path: 'dict',
  children: [
    {name: '运营城市', path: 'city'},
    {name: '所属区域', path: 'area'},
    {name: '所属小组', path: 'group'},
    {name: '微信账号', path: 'wx'},
  ]
}

export default function Layout() {
  const [expand, setExpand] = useState(true)
  if (!user) {
    return <Login />
  }

  const headUrl = (user.path + user.headUrl) || defaultHead

  const logout = (
    <div>
      <div className="logout-link hand" onClick={out}>退出登录</div>
    </div>
  )

  function out() {
    ajax.logout().then(res => {
      if (res.success) {
        setUser('')
        window.location.reload()
      }
    })
  }

  return (
    <div className="com-layout">
      <div className="com-header">
        <div className="logo"></div>
        <div className="nav-wrap">
          <Popover content={logout} trigger="click">
            <div className="nav-item">
              <div className="avatar" style={{backgroundImage: `url(${headUrl})`}}></div>
              <div className="user-name f-600">{user.username}</div>
            </div>
          </Popover>
        </div>
      </div>
      <div className="com-wrap">
        <div className={`com-menu ${expand}`}>
          <div className={`toggle ${expand}`} onClick={() => setExpand(!expand)}></div>
          <MenuItem path="/" />
          {user.isManage === 1 && (
            <>
              <MenuItem path="/team" />
              <MenuItem path="/transform" />
              <MenuItem path="/progress" />
              <MenuItem path="/account/list" />
              <MenuGroup value={dictRoute} pack={expand} />
            </>
          )}
          <MenuItem path="/operate-data/list" />
        </div>
        <Outlet />
      </div>
    </div>
  )
}

// 单个一级菜单
function MenuItem({path}) {
  const icon = path.split('/')[1] || 'index'
  const navigate = useNavigate()
  const location = useLocation()

  function go() {
    navigate(path)
  }

  const current = location.pathname.split('/')[1] || 'index'
  const active = current === icon

  return (
    <div className="menu-item">
      <div className={`menu ${active}`} onClick={go}>
        <div className={`icon ${icon}`}></div>
        <div className="name">{routeName[icon]}</div>
      </div>
    </div>
  )
}

// 菜单组
function MenuGroup({value, pack}) {
  const [expand, setExpand] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  function toggle() {
    setExpand(!expand)
  }
  function go(path) {
    navigate(`/${value.path}/${path}`)
  }

  const path = location.pathname.split('/')
  const active = path[1] === value.path
  const sub = path[2]

  return (
    <div className={`menu-item ${pack && expand}`}>
      <div className={`menu ${active}`} onClick={toggle}>
        <div className={`icon ${value.path}`}></div>
        <div className="name">{value.name}</div>
        <div className={`arrow ${active ? 'up' : 'down'}`}></div>
      </div>
      <div className="sub-menu">
        {value.children.map(item => (
          <div key={item.path} className={`sub-menu-item ${item.path === sub}`} onClick={() => go(item.path)}>{item.name}</div>
        ))}
      </div>
      <div className="hover-menu-wrap">
        <div className="hover-menu">
          {value.children.map(item => (
            <div key={item.path} className={`sub-menu-item ${item.path === sub}`} onClick={() => go(item.path)}>{item.name}</div>
          ))}
        </div>
      </div>
    </div>
  )
}