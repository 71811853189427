import {useFastReducer} from '../components/hooks'
import Input from '../components/form/Input'
import Text from '../components/form/Text'
import Salary from '../components/form/Salary'
import toast from '../components/toast'
import {Select} from 'antd'
import ChooseCity from '../components/form/ChooseCity'
import ChooseAb from '../components/form/ChooseAb'
import {useEffect} from 'react'
import commonAjax from '../ajax/common'

let categoryCache

export default function PosForm({close, pos, city, onChange}) {
  const [state, dispatch] = useFastReducer(pos ? pos : {
    name: '',
    businessId: null,
    businessName: '',
    category: '',
    categoryId: null,
    categoryList: [],
    salaryType: 2,
    salaryMin: null,
    salaryMax: null,
    cityList: city ? [city] : [],
    address: '',
    description: '',
  })

  function setBusiness(obj) {
    dispatch({businessName: obj.name, businessId: obj.id})
  }

  useEffect(() => {
    if (categoryCache) {
      dispatch({categoryList: categoryCache})
    } else {
      commonAjax.getBusinessCate(2).then(res => {
        if (res.success) {
          let categoryList = res.data[2] || []
          categoryList = categoryList.map(it => ({label: it.name, value: it.id}))
          categoryCache = categoryList
          dispatch({categoryList})
        }
      })
    }
  }, [])

  function add() {
    if (!state.name) {
      toast.warn('请输入职位名称')
      return
    }
    if (!state.businessId) {
      toast.warn('请选择类别')
      return
    }
    if (!state.categoryId) {
      toast.warn('请输入职级')
      return
    }
    if (state.salaryType > 0) {
      if (!state.salaryMax) {
        toast.warn('请选择薪酬')
        return
      }
    }

    if (state.cityList.length === 0) {
      toast.warn('请选择工作城市')
      return
    }

    onChange({
      ...state,
      salaryMin: state.salaryMin || 0,
      salaryMax: state.salaryMax || 0,
      categoryList: undefined,
      city: state.cityList.map(it => it.name).join(','),
      cityIdList: state.cityList.map(it => it.id).join(','),
    })
  }

  return (
    <div className="com-modal pos-form">
      <div className="com-modal-wrap">
        <div className="modal-head">
          <div className="modal-title">{pos ? '编辑' : '新增'}职位信息</div>
          <div className="close-wrap" onClick={close}>
            <div className="close-icon"></div>
          </div>
        </div>
        <div className="modal-content pos-content">
          <div className="flex between">
            <div className="input-group">
              <div className="fix-width">
                <div className="label required">职位名称：</div>
              </div>
              <div style={{width: '220px'}}>
                <Input placeholder="请输入" value={state.name} onChange={v => dispatch({name: v})} />
              </div>
            </div>
            <div className="input-group">
              <div className="label required">类别：</div>
              <div style={{width: '120px'}}>
                <ChooseAb value={state.businessName} placeholder="请选择" onChange={setBusiness} />
              </div>
            </div>
            <div className="input-group">
              <div className="label required">职级：</div>
              <div style={{width: '120px'}}>
                <Select placeholder="请选择" value={state.categoryId} options={state.categoryList} onChange={(v, o) => dispatch({categoryId: v, category: o.label})} />
              </div>
            </div>
          </div>
          <div className="input-group">
            <div className="fix-width">
              <div className="label required">薪酬：</div>
            </div>
            <Salary type={state.salaryType} from={state.salaryMin} to={state.salaryMax} onChange={dispatch} />
          </div>
          <div className="input-group">
            <div className="fix-width">
              <div className="label required">工作城市：</div>
            </div>
            <div style={{width: '260px', marginRight: '15px'}}>
              <ChooseCity value={state.cityList} onChange={v => dispatch({cityList: v})} />
            </div>
            <Input placeholder="具体地址（选填）" value={state.address} onChange={v => dispatch({address: v})} />
          </div>
          <div className="input-group with-text">
            <div className="fix-width">
              <div className="label">职位详情：</div>
            </div>
            <Text placeholder="选填" value={state.description} onChange={v => dispatch({description: v})} />
          </div>
        </div>
        <div className="modal-action">
          <div className="button block" onClick={add}>确认{pos ? '修改': '新增'}</div>
        </div>
      </div>
    </div>
  )
}