import './index.scss'
import { useFastReducer } from '../components/hooks'
import { user } from '../components'
import { useRef, useEffect } from 'react'
import { Select, DatePicker } from 'antd'
import ajax from '../ajax/dashboard'
import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import ComparePanel from './ComparePanel'
import CityPanel from './CityPanel'
dayjs.extend(quarterOfYear)

const weekDay = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
const target = ['100%', '90%', '80%', '70%']
const week = ['一', '二', '三', '四', '五']

export default function Dashboard() {
  const [state, dispatch] = useFastReducer({
    userList: [],
    cityList: [],
    user: null,
    city: null,
    isNow: false,
    countDate: dayjs(),
    dateType: 1,
    dateTypeArr: ['', 'month', 'quarter', 'year'],
    date: dayjs().month(dayjs().month()),
    startTime: '',
    endTime: '',
    userIds: [],
    isManager: false,
    LineChartData: null,
    chartType: 2,
  })

  function disabledDate(current) {
    return current && current > dayjs().endOf('day')
  }
  function inputFormat(value) {
    return value.format('YYYY.MM.DD') + ' ' + weekDay[value.day()]
  }

  function changeCountDate(countDate) {
    let startTime = countDate.startOf('month').format('YYYY-MM-DD')
    let endTime = countDate.endOf('month').format('YYYY-MM-DD')
    if (state.isNow) {
      startTime = dayjs().format('YYYY-MM-DD')
    }
    dispatch({ countDate, date: countDate, dateType: 1, startTime, endTime, })
  }
  //获取关键数据
  function getDataNum() {
    if (state.user && state.city) {
      const params = {
        date: state.countDate.format('YYYY-MM-DD'),
        cityId: state.city,
        userId: state.user
      }
      ajax.getDataNum(params).then(res => {
        if (res.success) {
          dispatch({
            ...res.data,
            maxWeekTarget: Math.max.apply(null, res.data.teamWeekList.map(item => item.tweekGoalNum)),
          })
        }
      })
    }
  }
  //获取所有用户
  function getAllUser() {
    ajax.getAllUser().then(res => {
      if (res.success) {
        let userList = res.data.map(item => ({ label: item.username, value: item.id, cityList: item.cityList }))
        let chartUserList = []
        let i = 1
        res.data.forEach(item => {
          item.cityList.forEach(it => {
            chartUserList.push({
              label: item.username + '-' + it.cityName,
              value: i++,
              userId: item.id,
              cityId: it.id
            })
          })
        })
        let cityList = userList[0].cityList.map(item => ({ label: item.cityName, value: item.id }))
        dispatch({
          userList,
          user: userList[0].value,
          cityList,
          city: cityList[0].value,
          chartUserList,
          userIds: chartUserList.filter(item => item.userId === userList[0].value).map(item => item.value),
        })
      }
    })
  }
  //切换用户
  function changeUser(value) {
    let userList = [...state.userList]
    let item = userList.find(item => item.value === value)
    let cityList = item.cityList.map(item => ({ label: item.cityName, value: item.id }))

    //更新折线图用户筛选数据
    let chartUserList = [...state.chartUserList]
    let userIds = chartUserList.filter(item => item.userId === value).map(item => item.value)

    dispatch({ user: value, cityList, city: cityList[0].value, userIds })
  }
  //切换城市
  function changeCity(value) {
    if (state.isManager) {
      dispatch({ city: value })
    } else {
      dispatch({ city: value, userIds: [{ userId: state.user, cityId: value }] })
    }
  }
  //切换日期类型
  function changeDateType(value) {
    let date = null
    if (value === 1) {
      date = dayjs().month(dayjs().month())
    }
    if (value === 2) {
      date = dayjs().quarter(dayjs().quarter())
    }
    if (value === 3) {
      date = dayjs().year(dayjs().year())
    }
    dispatch({ dateType: value, date })
  }
  //切换日期
  function onChangeDate(date) {
    let startTime = ''
    let endTime = ''
    if (date) {
      startTime = date.startOf(state.dateTypeArr[state.dateType]).format('YYYY-MM-DD')
      endTime = date.endOf(state.dateTypeArr[state.dateType]).format('YYYY-MM-DD')
    }
    if (state.isNow) {
      startTime = dayjs().format('YYYY-MM-DD')
    }
    dispatch({ date, startTime, endTime, })
  }
  function handleChange(value) {
    dispatch({ userIds: value })
  }
  function changeNow() {
    dispatch({
      isNow: !state.isNow,
      startTime: dayjs().format('YYYY-MM-DD'),
    })
  }

  //获取折线图数据
  function getLineChartData() {
    if (state.userIds.length > 0 && state.startTime) {
      let userCityList
      if (state.isManager) {
        userCityList = state.chartUserList.filter(item => state.userIds.includes(item.value)).map(item => ({ userId: item.userId, cityId: item.cityId }))
      } else {
        userCityList = state.userIds
      }
      const params = {
        isNow: state.isNow ? 1 : 0,
        startTime: state.startTime,
        timeType: state.dateType,
        userCityList,
      }
      if (!state.isNow) {
        params.endTime = state.endTime
      }
      ajax.getLineChartData(params).then(res => {
        if (res.success) {
          dispatch({ LineChartData: res.data })
        }
      })
    } else {
      dispatch({ LineChartData: [{ dataLineVos: [] }] })
    }
  }

  useEffect(() => {
    //权限
    if (user) {
      const isManager = Boolean(user.isManage)
      dispatch({ isManager })
      if (isManager) {
        // 管理权限
        getAllUser()
      } else {
        let cityList = user.cityList.map(item => ({ label: item.cityName, value: item.id }))
        dispatch({
          user: user.id,
          cityList,
          city: cityList[0].value,
          userIds: [{ userId: user.id, cityId: cityList[0].value }],
        })
      }
    }
  }, [])

  useEffect(() => {
    getDataNum()
  }, [state.countDate, state.user, state.city])

  useEffect(() => {
    onChangeDate(state.date)
  }, [state.dateType])

  useEffect(() => {
    getLineChartData()
  }, [state.startTime, state.endTime, state.isNow, state.userIds])



  return (
    <div className='com-content'>
      <div className='dashboard-index'>
        <div className="title-wrap">
          <div className="title">
            数据看板
            <DatePicker value={state.countDate} format={inputFormat} disabledDate={disabledDate} onChange={changeCountDate} allowClear={false} />
          </div>
          {state.isManager &&
            <>
              <div className='select-user'>
                <Select placeholder="请选择用户" value={state.user} options={state.userList} onChange={changeUser} />
              </div>
              <div className='select-city'>
                <Select placeholder="请选择城市" value={state.city} options={state.cityList} onChange={changeCity} />
              </div>
            </>
          }
          {!state.isManager &&
            <>
              <div className='city-title'>运营城市</div>
              <div className='city-wrap'>
                {state.cityList && state.cityList.map((item, index) =>
                  <div className={`city ${item.value === state.city}`} key={index} onClick={() => changeCity(item.value)}>{item.label}</div>
                )}
              </div>
            </>
          }
        </div>
        <div className="content-wrap">
          <div className='count-wrap'>
            <div className='item-wrap blue'>
              <div className='item-type'>日</div>
              <div className='item-info day'>
                <div className='text1'><span className='num f-600'>{state.dayNum}</span>个</div>
                <div className='text2'>当日完成</div>
                <div className='chart'>
                  <div className='end' style={{ width: state.dayPercent + '%' }}><div className='pre' >{state.dayPercent}%</div></div>
                </div>
                <div className='desc'>当日目标：{state.dayStandardNum}</div>
                <div className='desc'>进度差值：<span className='yellow f-600'>{state.dayPoorNum}</span></div>
                <div className='msg'>日操范围：<span className='num'>{state.dayScope}</span></div>
              </div>
            </div>
            <div className='item-wrap'>
              <div className='item-type'>周</div>
              <div className='item-info week'>
                <div className='target'>本周目标：<span className='num heavy'>{state.weekStandardNum}</span></div>
                <div className='chart-box'>
                  <HalfPieCharts num={state.weekNum} data={state.weekPercent} />
                </div>
                {state.teamWeekList && state.teamWeekList.map((item, index) =>
                  <div className={`week-chart ${item.isThisWeek === 1} ${item.isThisWeek === 2 ? 'end' : ''}`} key={index}>
                    <div className='name'>第{week[index]}周·{item.tweekGoalNum}</div>
                    <div className='chart' style={{ width: 110 * ((item.tweekGoalNum) / (state.maxWeekTarget)) + 'px' }} >
                      {item.tweekNum > 0 && <div className='end' style={{ width: item.tweekPercent + '%' }} >{item.tweekNum}</div>}
                    </div>
                    {item.isThisWeek > 0 && <div className='pre'>{item.tweekPercent}%</div>}
                  </div>
                )}
              </div>
            </div>
            <div className='item-wrap'>
              <div className='item-type'>月</div>
              <div className='item-info month'>
                <div className='target'>本月目标：<span className='num heavy'>{state.monthGoalNum}</span></div>
                <WaterCharts num={state.monthNum} data={state.monthPercent} />
                <div className='desc'>
                  <div className='label'>进度标准：</div>
                  <div className='gray'>{state.monthStandardNum}</div>
                </div>
                <div className='desc'>
                  <div className='label'>进度差值：</div>
                  <div className='red'>{state.monthPoorNum}</div>
                </div>
              </div>
            </div>
            <div className='item-wrap star'>
              <div className='day-num'>
                <div className='heavy'>剩余工作日：</div>
                <div className='num'>{state.workDay}</div>
                <div className='heavy'>DAY</div>
              </div>
              <div className='table'>
                <div className='table-head'>
                  <div className='col-1'>目标分级</div>
                  <div className='col-2'>目标值</div>
                  <div className='col-3'>剩余</div>
                  <div className='col-4'>日均</div>
                </div>
                <div className='table-body'>
                  {state.targetLevelList && state.targetLevelList.map((item, index) =>
                    <div className={`row ${item.type === 1} ${index < 4 ? 'hover' : ''} star-${item.type}`} key={index}>
                      {item.type === 0 && <div className='col-1'>{target[index]}</div>}
                      {item.type === 1 && <div className='col-1'>升星</div>}
                      {item.type === 2 && <div className='col-1'>降星</div>}
                      <div className='col-2'>{item.target}</div>
                      <div className='col-3'>{item.residueCount}</div>
                      <div className='col-4'>{item.dayCount}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="contrast-wrap">
            <div className="contrast-item">
              <ComparePanel name="前日同期" date={state.lastDayDate} pre={state.lastDayNum} now={state.dayNum} />
            </div>
            <div className="contrast-item">
              <ComparePanel name="上月同期" date={state.lastMonthDate} pre={state.lastMonthNum} now={state.monthNum} />
            </div>
            <div className="contrast-item">
              <div className="red-title f-600">距离上一名还差</div>
              <div className='num-box'>
                <div className="number-wrap">
                  <span className="number heavy f-600">{state.beforePoorNum}</span>
                  <span className="f-12 unit">个</span>
                </div>
                {state.rankPhrase &&
                  <div className='tip-text'>
                    <div className='arrow'></div>
                    <div className='text'>{state.rankPhrase}</div>
                  </div>
                }
              </div>
              <div className='user-box'>
                <div>上一名：<span className='f-14 f-600'>{state.beforeNum}</span></div>
                <div className='flex align'>
                  <div className='head-img' style={{ backgroundImage: `url(${state.beforeHeadUrl}` }}></div>
                  {state.beforeName && <div>{state.beforeCityName}·{state.beforeName}</div>}
                </div>
              </div>
            </div>
            <div className="contrast-item">
              <div className="red-title f-600 flex align between">
                <div className="flex align">
                  距离
                  <div className="span-title"><div className="value">贡献值</div></div>
                  第一名还差
                </div>
                <div className="gray">{state.firstPoorNum}</div>
              </div>
              <div className='first-user'>
                <div className='head-img' style={{ backgroundImage: `url(${state.firstHeadUrl}` }}>
                  <div className='head-icon'></div>
                </div>
                <div className='first-name'>TOP1 {state.cityName||'-'}·{state.firstUsername||'-'}</div>
                <div className='first-num'>{state.firstNum}</div>
              </div>
            </div>
          </div>
          <CityPanel list={state.rankList}/>
          <div className='chart-wrap'>
            <div className='info-wrap'>
              <div className='chart-title'>个人数据图</div>
              {state.isManager && <div className='chart-desc'>默认呈现团队最低人员数据</div>}
              <div className={`date-wrap ${!state.isManager}`}>
                <div className={`date-type ${state.dateType === 1}`} onClick={() => changeDateType(1)}>月</div>
                <div className={`date-type ${state.dateType === 2}`} onClick={() => changeDateType(2)}>季</div>
                <div className={`date-type ${state.dateType === 3}`} onClick={() => changeDateType(3)}>年</div>
              </div>
              <DatePicker value={state.date} onChange={onChangeDate} picker={state.dateTypeArr[state.dateType]} />
              <div className='now-wrap'>
                <div className={`now ${state.isNow}`} onClick={changeNow}>至今</div>
              </div>
              {state.isManager &&
                <Select
                  mode="multiple"
                  size='middle'
                  value={state.userIds}
                  placeholder="请选择"
                  onChange={handleChange}
                  style={{ width: 200, height: 32 }}
                  options={state.chartUserList}
                />
              }
              {!state.isManager &&
                <div className='chart-type'>
                  <div className={`item ${state.chartType === 2}`} onClick={() => dispatch({ chartType: 2 })}>柱状图</div>
                  <div className={`item ${state.chartType === 1}`} onClick={() => dispatch({ chartType: 1 })}>趋势图</div>
                </div>
              }
            </div>
            <LineCharts manager={state.isManager} chartType={state.chartType} data={state.LineChartData} />
          </div>
        </div>
      </div>
    </div>
  )


}


function HalfPieCharts({ num, data }) {
  const chartRef = useRef()
  if (num > -1) {
    let myChart = window.echarts.init(chartRef.current)
    let newData = [
      { value: data * 1 },
      {
        value: 100 - data,
        label: { show: false }
      },
    ]
    const option = {
      animation: false,
      series: [
        {
          type: 'pie',
          emphasis: {
            disabled: true,
          },
          radius: ['110%', '190%'],
          center: ['50%', '97%'],
          color: ['#7b77eb', '#ffffff'],
          itemStyle:{
            borderWidth: 2,
            borderColor: '#7b77eb',
          },
          label: {
            position: 'inner',
            formatter: '{d}%',
            color: '#ffffff',
            fontSize: 9
          },
          labelLayout: {
            dx: 3
          },
          startAngle: 180,
          endAngle: 360,
          data: newData,
        }
      ]
    }
    myChart.clear()
    myChart.setOption(option)
  }

  return (
    <div className='half-pie'>
      <div ref={chartRef} className="half-pie-chart"></div>
      <div className='num'>
        <div className='heavy'>{num}</div>
        <div className='u'>个</div>
      </div>
    </div>
  )
}
function WaterCharts({ num, data }) {
  const chartRef = useRef()
  if (num > -1) {
    let myChart = window.echarts.init(chartRef.current)
    let newData = [
      {
        value: data / 100,
        itemStyle: {
          color: '#ffc000'
        }
      }
    ]

    const option = {
      animation: false,
      series: [
        {
          type: 'liquidFill',
          center: ['50%', '45%'],
          emphasis: {
            disabled: true,
          },
          phase: 0.1,
          amplitude: 5,//波浪高度
          radius: '85%',
          itemStyle: {
            shadowBlur: 10, // 水波阴影大小
            shadowOffsetY: 0,// 阴影竖直偏移量
          },
          label: {
            show: true,
            position: 'bottom',
            fontSize: 14,
            fontWeight: 600,
            color: '#ffc000',

          },
          labelLayout: {
            dy: 15
          },
          backgroundStyle: {
            color: '#FFFFFF',// 指定背景颜色
          },
          // 水波图边框配置
          outline: {
            show: true, // 是否显示边框，默认为false
            borderDistance: 3,// 边框距离水波图的距离，默认为0，也可以理解为边框宽度
            // 边框样式
            itemStyle: {
              borderWidth: 4, // 图例图形的描边
              borderColor: "#ffc001",// 图例图形描边的颜色
              shadowBlur: 0,// 图例的阴影模糊大小
            }
          },
          data: newData,
        }
      ]
    }
    myChart.clear()
    myChart.setOption(option)
  }

  return (
    <div className='water-pie'>
      <div ref={chartRef} className="water-chart"></div>
      <div className='num'>
        <div>{num}</div>
        <div className='u'>个</div>
      </div>
    </div>
  )
}
//图表
let lineChart
function LineCharts({ manager, data, chartType }) {
  const chartRef = useRef()

  function refreshLine() {
    lineChart.resize()
  }

  let res = new ResizeObserver(() => {
    lineChart.resize()
  })

  useEffect(function () {
    lineChart = window.echarts.init(chartRef.current)
    window.addEventListener('resize', refreshLine)
    res.observe(chartRef.current)
    return () => {
      window.removeEventListener('resize', refreshLine)
    }
  }, [])

  const series = [], nameArr = []
  if (data) {
    //数据
    data.forEach(item => {
      let name = ''
      if (manager) {
        name = item.username ? (item.username + '-' + item.city) : ''
        nameArr.push(name)
      }
      let type = 'line'
      if (!manager && chartType === 2) {
        type = 'bar'
      }
      let obj = {
        name,
        type,
        data: item.dataLineVos.map(item => item.num)
      }
      //数字标签
      if (!manager && chartType === 2) {
        obj.label = { show: true, position: 'top', color: '#7D79EC' }
        obj.itemStyle = { color: '#E7E6F5' }
      } else {
        obj.label = { show: true, position: 'top', color: '#7D79EC' }
      }
      series.push(obj)
    })

    if (lineChart) {
      lineChart.clear() //清除缓存数据
      const option = {
        animation: false,
        tooltip: {
          trigger: 'axis',
          order: 'valueDesc',
        },
        legend: {
          top: 10,
          left: '3%',
          data: nameArr
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: data[0].dataLineVos.map(item => item.time),
          axisLabel: {
            color: '#B0ADD2',
          },
          axisLine: {
            lineStyle: {
              color: '#B0ADD2',
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#B0ADD2'
          }
        },
        series: series
      }
      //绘制
      lineChart.setOption(option)
    }
  }
  return <div ref={chartRef} className="line-chart"></div>
}