import ajax from '../../ajax/common'
import {env} from '../index'

function loadSDK() {
  return new Promise(resolve => {
    const script = document.createElement('script')
    script.src = 'https://gosspublic.alicdn.com/aliyun-oss-sdk-6.17.0.min.js'
    script.onload = function () {
      resolve()
    }
    document.head.appendChild(script)
  })
}

let client

async function createClient() {
  if (!window.OSS) {
    await loadSDK()
  }
  const config = await ajax.getOSSConfig()
  if (config.success) {
    client = new window.OSS({
      region: 'oss-cn-chengdu',
      accessKeyId: config.data.accessKeyId,
      accessKeySecret: config.data.accessKeySecret,
      stsToken: config.data.securityToken,
      bucket: `baitong-recruit${env === 'pro' ? '-pro' : ''}`
    })
  }
}

// 默认根目录reirc_operate，文件名时间戳加3位随机数
function generateFileName(filePath, fileName) {
  let type = fileName.lastIndexOf('.')
  type = fileName.slice(type)
  const random = (Math.random() * 1000).toFixed()
  return `reirc_operate/${filePath}/${new Date().getTime()}${random}${type}`
}

async function uploadFile(filePath, file, oldFilename) {
  if (!client) {
    await createClient()
  }
  if (oldFilename) {
    client.delete(oldFilename)
  }
  const fileName = generateFileName(filePath, file.name)
  return await client.put(fileName, file)
}

export {uploadFile}
