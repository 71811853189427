import { oaService } from './index'

//账号管理列表
function getAccountList(params) {
  return oaService.post('/user/page-list', params)
}

//禁用/启用-用户
function disableAccount(params) {
  return oaService.put('/user/disable', params)
}

//创建运营账号
function addOptAccount(params) {
  return oaService.post('/user', params)
}

//编辑
function updateOptAccount(params) {
  return oaService.put('/user', params)
}

//删除
function deleteOptAccount(id) {
  return oaService.delete('/user?id=' + id)
}

//详情
function getOptAccountDetails(userId) {
  return oaService.get('/user/details?userId=' + userId)
}

// 查询运营城市下的账号
function getAccountByOpCity(params) {
  return oaService.post('/user/wx-data', params)
}

export default {
  getAccountList,
  disableAccount,
  addOptAccount,
  updateOptAccount,
  deleteOptAccount,
  getOptAccountDetails,
  getAccountByOpCity,
}