import {oaService} from './index'

// 获取oss config
function getOSSConfig() {
  return oaService.get('/oss')
}
// 获取字典 3行业类别
function getBusinessCate(type) {
  return oaService.get('/dict/getGeneral' + (type ? '?type=' + type : ''))
}
// 获取内容制作人
function getDict(params) {
  return oaService.get('/dict/getGeneral', {params})
}

function logout() {
  return oaService.get('/user/logout')
}

export default {
  getOSSConfig,
  getBusinessCate,
  getDict,
  logout,
}