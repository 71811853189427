import { useFastReducer } from '../components/hooks'
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from '../common/Breadcrumb'
import Avatar from '../components/form/Avatar'
import Input from '../components/form/Input'
import Sex from '../components/form/Sex'
import ChoosePanel from '../components/form/ChoosePanel'
import ChooseCity from '../components/form/ChooseCity'
import { Select } from 'antd'
import './accountForm.scss'
import { useEffect } from 'react'
import toast from '../components/toast'
import ajax from '../ajax/account'
import showConfirm from '../components/confirm'

export default function AccountForm() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [state, dispatch] = useFastReducer({
    avatar: null, // {path, url}
    name: '',
    phone: '',
    age: '',
    sex: '',
    group: '',
    star: null,
    starList: [
      { label: '无星级', value: 0 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 },
    ],
    accountList: [],
    areaList: [],
    opCityList: [],
    expCityList: [],
    linkCityList: [],
    goal: '',
  })

  //创建 编辑
  function confirmThis() {
    if (!state.avatar) {
      toast.warn('请上传头像')
      return
    }
    if (!state.name) {
      toast.warn('请输入姓名')
      return
    }
    if (!state.phone) {
      toast.warn('请输入手机号')
      return
    }
    if (state.sex === '') {
      toast.warn('请选择性别')
      return
    }
    if (!state.age) {
      toast.warn('请输入年龄')
      return
    }
    // if (!state.group) {
    //   toast.warn('请输入小组名称')
    //   return
    // }
    if (state.star === null) {
      toast.warn('请选择星级')
      return
    }
    if (state.areaList.length === 0) {
      toast.warn('请选择区域')
      return
    }
    if (state.opCityList.length === 0) {
      toast.warn('请选择运营城市')
      return
    }
    if (state.accountList) {
      const flag = []
      state.accountList.forEach(item => {
        if (item.accountIdList.length === 0) {
          flag.push(item.name)
        }
      })
      if (flag.length > 0) {
        toast.warn(`${flag.join(',')}暂无绑定微信账号，请重新选择运营城市`)
        return
      }
    }
    if (state.expCityList.length === 0) {
      toast.warn('请选择辐射城市')
      return
    }
    if (state.linkCityList.length === 0) {
      toast.warn('请选择关联城市')
      return
    }
    const goalList = state.opCityList.map(item => ({
      cityId: Number(item.id),
      target: Number(state['goal' + item.id])
    }))
    if (!goalList.every(item => item.target)) {
      toast.warn('请输入月目标')
      return
    }

    const params = {
      path: state.avatar.path,
      headUrl: state.avatar.url,
      username: state.name,
      phone: Number(state.phone),
      age: Number(state.age),
      sex: Number(state.sex),
      // groupName: state.group,
      starTarget: Number(state.star),
      areaId: Number(state.areaList[0].id),
      operateCity: state.opCityList.map(item => item.name).join(','),
      operateCityIdList: state.opCityList.map(item => item.id).join(','),
      radiationCity: state.expCityList.filter(item => item.id).map(item => item.name).join(','),
      radiationCityIdList: state.expCityList.filter(item => item.id).map(item => item.id).join(','),
      radiationProvince: state.expCityList.filter(item => !(item.id)).map(item => item.name).join(','),
      radiationProvinceIdList: state.expCityList.filter(item => !(item.id)).map(item => item.pid).join(','),
      relevanceCity: state.linkCityList.map(item => item.name).join(','),
      relevanceCityIdList: state.linkCityList.map(item => item.id).join(','),
      userTargetList: goalList,
    }

    if (id) {
      //编辑
      params.id = id
      ajax.updateOptAccount(params).then(res => {
        if (res.success) {
          toast.success('修改运营账号成功')
          goBack()
        }
      })
    } else {
      //创建
      ajax.addOptAccount(params).then(res => {
        if (res.success) {
          toast.success('创建运营账号成功')
          goBack()
        }
      })
    }
  }
  //删除
  function deleteThis() {
    showConfirm(
      {
        title: '提示',
        msg: `是否确认删除该账号？`,
      },
      () => {
        ajax.deleteOptAccount(id).then(res => {
          if (res.success) {
            toast.success('删除成功')
            goBack()
          }
        })
      },
    )
  }

  function goBack() {
    setTimeout(() => {
      navigate(-1)
    }, 1500)
  }

  useEffect(() => {
    //编辑回显
    if (id) {
      ajax.getOptAccountDetails(id).then(res => {
        if (res.success) {
          let data = res.data
          const params = {
            avatar: data.headUrl ? { path: data.path, url: data.headUrl } : null,
            name: data.username,
            phone: data.phone,
            age: data.age,
            sex: data.sex,
            // group: data.groupName,
            star: data.starTarget || null,
            areaList: data.area ? [data.area] : [],
            opCityList: (data.operateCity || []).map(item => ({ id: item.id, name: item.cityName })),
            expCityList: [
              ...(data.radiationCity || []).map(item => ({ pid: item.pid, id: item.id, name: item.cityName })),
              ...(data.radiationProvince || []).map(item => ({ pid: item.id, name: item.cityName }))
            ],
            linkCityList: (data.relevanceCity || []).map(item => ({ id: item.id, name: item.cityName })),
          }
          data.userTargetList.forEach(item => {
            params['goal' + item.cityId] = item.target
          })
          dispatch(params)
          if (data.operateCity && data.operateCity.length > 0) {
            getAccountByOpCity(data.operateCity.map(item => item.id))
          }
        }
      })
    }
  }, [])

  function getAccountByOpCity(idList) {
    ajax.getAccountByOpCity({idList}).then(res => {
      if (res.success) {
        dispatch({accountList: res.data})
      }
    })
  }

  function setOpCity(v) {
    dispatch({ opCityList: v })
    getAccountByOpCity(v.map(item => item.id))
  }

  return (
    <div className="com-content">
      <div className="account-form-wrap">
        <Breadcrumb path="/account/form" id={id} />
        <div className="form-panel">
          <div className="caption f-600">{id ? '编辑' : '创建'}运营账号</div>
          <div className="title f-500">基础信息</div>
          <div className="input-section">
            <div className="input-label">头像：</div>
            <Avatar value={state.avatar} onChange={v => dispatch({ avatar: v })} />
          </div>
          <div className="input-section">
            <div className="input-label">姓名：</div>
            <div className="fix-width">
              <Input value={state.name} placeholder="请输入姓名" onChange={v => dispatch({ name: v })} />
            </div>
          </div>
          <div className="input-section">
            <div className="input-label">手机号：</div>
            <Input type="number" value={state.phone} placeholder="请输入真实手机号" onChange={v => dispatch({ phone: v })} />
          </div>
          <div className="flex between">
            <div className="input-section">
              <div className="input-label">性别：</div>
              <div className="fix-width">
                <Sex value={state.sex} onChange={(v) => dispatch({ sex: v })} />
              </div>
            </div>
            <div className="input-section star-level">
              <div className="flex-label">年龄：</div>
              <Input type="number" value={state.age} placeholder="请输入真实年龄" onChange={v => dispatch({ age: v })} />
            </div>
          </div>
          <div className="flex between">
            {/*<div className="input-section">*/}
            {/*  <div className="input-label">所属小组：</div>*/}
            {/*  <div className="fix-width">*/}
            {/*    <Input value={state.group} placeholder="请输入所属小组名称" onChange={v => dispatch({ group: v })} />*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="input-section star-level">
              <div className="input-label">星级：</div>
              <Select placeholder="请选择" value={state.star} options={state.starList} onChange={v => dispatch({ star: v })} />
            </div>
          </div>
          <div className="title f-500">负责城市</div>
          <div className="input-section">
            <div className="input-label">所属区域：</div>
            <div className='short-wrap'>
              <ChoosePanel type='area' single title='请选择所属区域' placeholder='请选择（单选）' value={state.areaList} onChange={(v) => dispatch({ areaList: v })} />
            </div>
          </div>
          <div className="input-section">
            <div className="input-label">运营城市：</div>
            <ChoosePanel type='city' title='请选择运营城市' placeholder='请输入您运营的城市（可多选）' value={state.opCityList} onChange={setOpCity} />
          </div>
          {state.accountList.length > 0 && <AccountList list={state.accountList} />}
          {state.opCityList.length > 0 && (
            <div className="input-section">
              <div className="input-label">月目标：</div>
              <div className='goal-wrap'>
                {state.opCityList.map((item, index) =>
                  <div className="input-section" key={index}>
                    <div className="input-label">{item.name}：</div>
                    <div className="fix-width">
                      <Input type="number" name={'goal' + item.id} value={state['goal' + item.id] || ''} placeholder="请输入" onChange={v => dispatch({ ['goal' + item.id]: v })} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="input-section">
            <div className="input-label">辐射城市：</div>
            <ChooseCity all value={state.expCityList} placeholder='请输入您辐射的城市（可多选）' onChange={v => dispatch({ expCityList: v })} />
          </div>
          <div className="input-section">
            <div className="input-label">关联城市：</div>
            <ChoosePanel type='city' title='请选择关联城市' placeholder='请输入您关联的城市（可多选）' value={state.linkCityList} onChange={(v) => dispatch({ linkCityList: v })} />
          </div>
          <div className='flex action-wrap'>
            {id && <div className='button hollow' onClick={deleteThis}>删除</div>}
            <div className='button block' onClick={confirmThis}>确认{id ? '修改' : '创建'}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

function AccountList({list}) {
  return (
    <div className="account-list f-12">
      {list.map((item, i) => (
        <div key={i}>
          {item.accountIdList.length > 0 ?  (
            <div className="city-item">
              <div className="label">{item.name}：</div>
              <div className="list">
                {item.accountIdList.map((it, index) => (
                  <div key={index} className="item">{it.name}</div>
                ))}
              </div>
            </div>
            ) : (
            <div className="city-item red">
              {item.name}暂无绑定微信账号，请前往创建。
            </div>
          )}
        </div>
      ))}
    </div>
  )
}