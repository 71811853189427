import {useState} from 'react'

export default function Input({value, placeholder, onChange}) {
  const [focus, setFocus] = useState(false)

  function on() {
    setFocus(true)
  }
  function off() {
    setFocus(false)
  }
  function change(e) {
    onChange(e.target.value)
  }

  return (
    <div className={`com-text ${focus}`}>
      <textarea
        autoComplete="off"
        className="text"
        placeholder={placeholder}
        value={value}
        onFocus={on}
        onBlur={off}
        onChange={change}
      />
    </div>
  )
}