import { useFastReducer } from '../hooks'
import ajax from '../../ajax/dict'
import { useEffect } from 'react'
import Input from '../form/Input'
import './chooseArea.scss'
import toast from '../toast'
import {removeItemAtIndex} from '../index'

let listCache

export default function ChooseArea({
   close,
   value,
   inputValue = '',
   input = false, // 是否有输入框
   title = '',
   all = false, // 是否选省
   single = false, // 单选
   maxLength = 20,
   onChange
}) {
  const [state, dispatch] = useFastReducer({
    desc: '',
    list: [],
    checkList: [],
    provinceIndex: 0,
    keyword: '',
    search: null,
  })

  const checkId = state.checkList.map(item => (item.id || item.pid))

  function changeProvince(index) {
    dispatch({ provinceIndex: index, keyword: '', search: null })
  }

  function enterToSearch(e) {
    if (e.key === 'Enter') {
      search()
    }
  }
  function search() {
    if (state.keyword) {
      ajax.searchDistrict(state.keyword).then(res => {
        if (res.success) {
          dispatch({
            provinceIndex: -1,
            search: res.data || []
          })
        }
      })
    } else {
      toast.warn('请输入城市名')
    }
  }

  function chooseAllCity() {
    if (state.checkList.length === maxLength) {
      toast.error(`最多可选${maxLength}个`)
      return
    }
    let province = state.list[state.provinceIndex]
    //已选中 返回
    if (checkId.includes(province.id)) return

    if (single) {
      dispatch({
        checkList: [{ pid: province.id, name: province.name }]
      })
      return
    }

    let checkList = state.checkList
    //选中全部 清除该省选中的城市
    checkList = checkList.filter(item => item.pid !== province.id)
    //选中全部
    checkList.push({ pid: province.id, name: province.name })
    dispatch({ checkList })
  }

  function chooseCity(index) {
    if (state.checkList.length === maxLength) {
      toast.error(`最多可选${maxLength}个`)
      return
    }
    let city
    if (state.provinceIndex === -1) {
      city = state.search[index]
    } else {
      city = state.list[state.provinceIndex].next[index]
    }
    //已选中 返回
    if (checkId.includes(city.id)) return
    //单选
    if (single) {
      dispatch({
        checkList: [{ pid: city.pid, id: city.id, name: city.name }],
      })
      return
    }
    //多选
    let checkList = state.checkList
    //选中城市 清除全部
    checkList = checkList.filter(item => !(item.pid === city.pid && !item.id))
    //选中
    checkList.push({ pid: city.pid, id: city.id, name: city.name })
    dispatch({ checkList })
  }

  function removeItem(index) {
    dispatch({ checkList: removeItemAtIndex(state.checkList, index) })
  }

  function confirm() {
    if (input && !state.desc) {
      toast.warn('请输入区域名称')
      return
    }
    if (state.checkList.length === 0) {
      toast.warn('请选择城市')
      return
    }
    if (input) {
      onChange({
        areaName: state.desc,
        areaList: state.checkList
      })
    } else {
      onChange(state.checkList)
    }
  }

  useEffect(() => {
    if (listCache) {
      dispatch({list: listCache, desc: inputValue,  checkList: value})
    } else {
      console.log(value)
      dispatch({desc: inputValue,  checkList: value})
      ajax.getDistrict().then(res => {
        if (res.success) {
          listCache = res.data
          dispatch({list: res.data})
        }
      })
    }
  }, [])


  return (
    <div className='com-choose-area-model'>
      <div className='area-wrap'>
        <div className='area-title-wrap'>
          <div className='title'>{title || '请选择城市'}</div>
          <div className='close' onClick={close}></div>
        </div>
        <div className='area-content-wrap'>
          {input &&
            <div className='input-wrap'>
              <div className='label'>所属区域：</div>
              <Input
                placeholder='请输入所属区域'
                value={state.desc}
                onChange={v => dispatch({ desc: v })}
              />
            </div>
          }
          <div className="search-wrap">
            <Input
              placeholder='请输入城市名'
              value={state.keyword}
              onKeyDown={enterToSearch}
              onChange={v => dispatch({ keyword: v })}
            />
            <div className="search-btn hand" onClick={search}>搜索</div>
          </div>
          <div className='area-city-box'>
            <div className='province-wrap'>
              {state.list.map((item, index) =>
                <div
                  className={`item ${state.provinceIndex === index}`}
                  key={index}
                  onClick={() => changeProvince(index)}
                >
                  {item.name}
                </div>
              )}
            </div>
            <div className='city-wrap'>
              {state.provinceIndex === -1 ? (
                state.search ? (state.search.length > 0 ? (
                  <div className='item-wrap'>
                    {state.search.map((item, index) =>
                      <div className={`item ${checkId.includes(item.id)}`} key={index} onClick={() => chooseCity(index)}>{item.name}</div>
                    )}
                  </div>
                ) : <div className="tip">未查询到相关城市</div>) : null
              ) : (
                <div className='item-wrap'>
                  {(state.provinceIndex > 0 && (state.list[state.provinceIndex].next.length > 1) && all) &&
                    <div className={`item ${checkId.includes(state.list[state.provinceIndex].id)}`} onClick={chooseAllCity}>全部</div>
                  }
                  {state.list[state.provinceIndex]?.next.map((item, index) =>
                    <div className={`item ${checkId.includes(item.id)}`} key={index} onClick={() => chooseCity(index)}>{item.name}</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {state.checkList.length > 0 &&
          <div className='check-wrap'>
            <div className='label'>已选：</div>
            <div className='check-box'>
              {state.checkList.map((item, index) =>
                <div className='item' key={index}>
                  {item.name}
                  <div className='del-icon' onClick={() => removeItem(index)}></div>
                </div>
              )}
            </div>
          </div>
        }
        <div className='button-wrap'>
          <div className='confirm' onClick={confirm}>确认</div>
        </div>
      </div>
    </div>
  )
}