import Axios from 'axios'
import toast from '../components/toast'
import {env, user, setUser} from '../components'

Axios.defaults.headers.common['request-from'] = 'web_o'

// 根据环境和不同服务，设置不同baseURL
const domain = {
  dev: 'https://test-job.mynatapp.cc',
  test: 'https://test-job.mynatapp.cc',
  pro: 'https://backservice.dcntq.com'
}

// 登录服务单独列出一个，因为不需要注册请求结果拦截
const loginService = Axios.create({
  baseURL: domain[env] + '/api/oa-service'
})
const oaService = Axios.create({
  baseURL: domain[env] + '/api/oa-service'
})

function resolve(res) {
  // 业务异常
  if (!res.data.success) {
    // 未登录1022，token过期1013
    if (res.data.code === 1022 || res.data.code === 1013) {
      // 登录过期，清空user
      setUser('')
      toast.error('登录过期')
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    } else {
      toast.error(res.data.message)
    }
  }
  return res.data
}
function reject() {
  toast.error('请求接口失败')
  return { success: false, message: '请求接口失败', data: {} }
}
oaService.interceptors.response.use(resolve, reject)

// 登录后，设置每个业务请求实例的access token
function setAccessToken(token) {
  oaService.defaults.headers.common['access-token'] = token
}
if (user) {
  setAccessToken(user.accessToken)
}

export {
  setAccessToken,
  loginService,
  oaService,
}