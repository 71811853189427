import {useState} from 'react'
import ChooseArea from '../choose-area/ChooseArea'

export default function ChooseCity({value, all = false, single, placeholder, length, onChange}) {
  const [modal, setModal] = useState(false)

  function open() {
    setModal(true)
  }

  function close() {
    setModal(false)
  }

  function change(v) {
    setModal(false)
    onChange(v)
  }

  const str = value.map(item => item?.name).join(',')

  return (
    <div className="com-choose-city">
      <div className="com-input with-arrow" onClick={open}>
        {str ? <div className="input only-text">{str}</div> : <div className="input gray-text">{placeholder || '请选择'}</div>}
      </div>
      {modal && <ChooseArea all={all} close={close} value={value} single={single} maxLength={length} title="请选择城市" onChange={change} />}
    </div>
  )
}