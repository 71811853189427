import {useState, useEffect} from 'react'
import ajax from '../ajax/operateData'

export default function TableCanvas({date, displayWidth}) {
  const width = 4042
  const headHeight = 248
  const lineHeight = 60 // 一行高50
  const footHeight = 60
  const col = [145, 174, 172, 380, 174, 174, 171, 174, 172, 172, 172, 173, 381, 171, 173, 240, 172, 172, 240] // 22个
  const lineColor = '#646464' // 线的颜色
  let canvas, ctx
  const [height, setHeight] = useState(0)
  const [data, setData] = useState(null)

  useEffect(() => {
    ajax.getTable(date).then(res => {
      if (res.success) {
        setData(res.data)
        let bodyHeight = 0
        res.data.operateList.forEach(it => {
          bodyHeight += Math.round(it.wxFriendCountList.length * lineHeight)
        })
        setHeight(Math.round(headHeight + bodyHeight + footHeight))
      }
    })
  }, [])

  useEffect(() => {
    if (height > 0) {
      canvas = document.getElementById('canvas')
      ctx = canvas.getContext('2d')

      const img = new Image()
      img.src = 'https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_operate/data/table4.jpg'
      img.crossOrigin = 'Anonymous'
      img.onload = () => {
        ctx.drawImage(img, 0, 0)
        // 画日期
        ctx.fillStyle = '#FFFFFF'
        ctx.font = 'bold 48px sans-serif'
        const date = data.publishTime.split('.')
        ctx.fillText(`${date[0]}年${date[1]}月${date[2]}日 · 数据`, 1650, 80)
        // 画月份
        ctx.fillStyle = '#000000'
        ctx.font = 'bold 24px sans-serif'
        ctx.fillText(date[1], 3625, 42)
        ctx.fillText(data.workDayCount, 3670, 104)
        drawNumber(data.progressRatio, 3802, 104, 240)
        drawNumber(data.residueWorkDayCount, 2978, 226, 240)
        // 一行一行画数据
        ctx.strokeStyle = lineColor
        let top = headHeight
        data.operateList.forEach((it, index) => {
          const height =  Math.round(it.wxFriendCountList.length * lineHeight)
          // 行背景交替
          // const bg = (index % 2) === 0 ? '#F7EEF8' : '#CFEDE7'
          drawDataRow(top, height, it)
          top += height
        })
        // 画table footer
        drawFoot(top)
      }
    }
  }, [height])

  function drawDataRow(top, height, it) {
    // 画背景色
    ctx.fillStyle = it.color ? '#' + it.color : '#F7EEF8'
    ctx.fillRect(0, top, width, height)
    drawRowLine(top)
    drawColLines(top, height, it)
  }

  function drawRowLine(top) {
    ctx.beginPath()
    ctx.moveTo(0, top)
    ctx.lineTo(width, top)
    ctx.closePath()
    ctx.stroke()
  }
  function drawColLines(top, height, it) {
    const y = Math.round(top + (height + 24) /2)
    ctx.fillStyle = '#000000'
    ctx.font = 'bold 24px sans-serif'
    drawNumber(it.username, 0, y, col[0])
    const colData = [
      it.star,
      it.cityName,
      it.enterpriseName,
      it.readCount,
      it.fansCount,
      it.addCount,
      '',
      it.wxAddCount,
      it.diffusionCount,
      it.groupCount,
      it.hrCount,
      it.enterprise,
      it.wxTransformCount,
      it.cost,
      it.residueDayTarget,
      it.targetCount,
      it.completeCount,
      it.cityTarget,
      it.percent,
    ]
    let left = 0
    const bottom = Math.round(top + height)
    col.forEach((c, index) => {
      left = Math.round(left + c)
      drawColLine(left, top, bottom)
      if (index === 6) {
        let tempY = Math.round(top + (lineHeight + 24) /2)
        const span = col[index + 1] || Math.round(width - left)
        it.wxFriendCountList.forEach((wx, i) => {
          drawNumber(wx, left, tempY, col[index + 1])
          tempY += lineHeight
          if (i > 0) {
            const tempTop = Math.round(top + i * lineHeight)
            ctx.beginPath()
            ctx.moveTo(left, tempTop)
            ctx.lineTo(Math.round(left + span), tempTop)
            ctx.closePath()
            ctx.stroke()
          }
        })
      } else {
        ctx.fillStyle = '#000000'
        if (index === 12 || index === 15 || index === 16) {
          ctx.fillStyle = 'red'
        }
        let str = colData[index]
        if (index === 2 && str && str.length > 11) {
          str = str.slice(0, 11) + '...'
        }
        drawNumber(str, left, y, col[index + 1])
      }
    })
  }
  function drawColLine(left, top, bottom) {
    ctx.beginPath()
    ctx.moveTo(left, top)
    ctx.lineTo(left, bottom)
    ctx.closePath()
    ctx.stroke()
  }

  // 画 table footer
  function drawFoot(top) {
    // 画背景
    ctx.fillStyle = '#3D3D91'
    ctx.fillRect(0, Math.round(height - footHeight), width, footHeight)
    // 画footer头顶那根线
    drawRowLine(top)
    const y = Math.round(height - 20)
    // 画合计
    ctx.fillStyle = '#FFFFFF'
    ctx.font = 'bold 24px sans-serif'
    ctx.fillText('合计', 411, y)
    // 画数据
    const footerData = [
      '','','',
      data.totalReadCount,
      data.totalFansCount,
      data.totalAddCount,
      data.totalWxFriendCount,
      data.totalWxAddCount,
      data.totalDiffusionCount,
      data.totalGroupCount,
      data.totalHrCount,
      data.totalEnterpriseCount,
      data.totalWxTransformCount,
      data.totalCost,
      data.totalResidueDayTarget,
      data.totalTargetCount,
      data.totalCompleteCount,
      data.totalCityTarget,
      data.totalPercent,
    ]
    let left = 0
    col.forEach((it, index) => {
      left = Math.round(left + it)
      if (index > 2) {
        drawColLine(left, top, height)
        drawNumber(footerData[index], left, y, col[index + 1])
      }
    })
  }
  // 公用画单元格数据方法
  function drawNumber(value, left, top, span) {
    // 单元格所占宽度 最后一个的宽要自己计算
    let x = span || Math.round(width - left)
    x = Math.round(left + (x - ctx.measureText(value).width) / 2)
    if (value !== undefined) {
      ctx.fillText(value, x, top)
    }
  }

  const styleWidth = displayWidth || Math.round(width/2)
  const styleHeight = Math.round(styleWidth * height / width)
  return (
    <canvas
      className="canvas"
      style={{width: styleWidth + 'px', height: styleHeight + 'px'}}
      id="canvas"
      width={width}
      height={height}
    ></canvas>
  )
}