import { useEffect, useState } from "react"


export default function Sex({ value, onChange }) {
  const [sex, setSex] = useState(null)

  function change(sex) {
    setSex(sex)
    onChange(sex)
  }

  useEffect(() => {
    setSex(value)
  }, [value])

  return (
    <div className="com-sex">
      <div className={`item ${sex === 1}`} onClick={() => change(1)}>男</div>
      <div className={`item ${sex === 0}`} onClick={() => change(0)}>女</div>
      <div className={`item w ${sex === 2}`} onClick={() => change(2)}>其他</div>
    </div>
  )
}