import Breadcrumb from '../common/Breadcrumb'
import { useFastReducer } from '../components/hooks'
import showConfirm from '../components/confirm'
import { useEffect } from 'react'
import ajax from '../ajax/dict'
import { Pagination } from 'antd'
import toast from '../components/toast'
import Text from '../components/form/Text'
import './dict.scss'

export default function GroupList() {
  const [state, dispatch] = useFastReducer({
    page: 1,
    size: 15,
    isModel: false,
    list: null
  })

  //新增
  function add() {
    dispatch({ isModel: true, item: null })
  }
  //编辑
  function edit(item) {
    dispatch({ isModel: true, item })
  }
  function closeModel() {
    dispatch({ isModel: false })
  }

  //删除
  function delItem(id) {
    showConfirm(
      {
        title: '提示',
        msg: '是否确认删除该小组？',
      },
      () => {
        ajax.deleteGroup(id).then(res => {
          if (res.success) {
            toast.success('删除成功')
            getList()
          }
        })
      },
    )
  }

  //获取列表
  function getList() {
    const params = {
      pageNum: state.page,
      pageSize: state.size
    }
    ajax.getGroupList(params).then(res => {
      if (res.success) {
        dispatch({
          list: res.data.records,
          total: res.data.total,
        })
      }
    })
  }

  // 分页切换
  function pageChange(page) {
    dispatch({ page })
  }

  useEffect(() => {
    getList()
  }, [state.page])

  function refresh() {
    if (state.item) {
      getList()
    } else {
      if (state.page === 1) {
        getList()
      } else {
        dispatch({page: 1})
      }
    }
  }

  return (
    <div className="com-content dict-list">
      <div className="com-fix-wrap">
        <div className="flex align between">
          <Breadcrumb path="/dict/group" />
          <div className="plus-button" onClick={add}>新增所属小组</div>
        </div>
        <div className='table-wrap'>
          <div className='table-head'>
            <div className='col-start'>所属小组名字</div>
            <div className='col-grow'>创建时间</div>
            <div className='col-end'>操作</div>
          </div>
          <div className='table-content'>
            {state.list && state.list.map((item, index) =>
              <div className='row' key={index}>
                <div className='col-start'>{item.name}</div>
                <div className='col-grow'>{item.createTime}</div>
                <div className='col-end'>
                  <div className='del-btn' onClick={() => delItem(item.id)}><div className='del-icon' />删除</div>
                  <div className='edit-btn' onClick={() => edit(item)}><div className='edit-icon' />编辑</div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='pagination'>
          <Pagination align="end" showQuickJumper current={state.current} pageSize={state.size} total={state.total} onChange={pageChange} />
        </div>
      </div>
      {state.isModel && <EditModel close={closeModel} item={state.item} refresh={refresh} />}
    </div>
  )
}


function EditModel({ close, item, refresh}) {
  const [state, dispatch] = useFastReducer({
    desc: item ? item.name : '',
  })

  function confirm() {
    if (!state.desc) {
      toast.warn('请填写小组名称')
      return
    }
    if (item) {
      //编辑
      ajax.editGroup({ id: item.id, name: state.desc }).then(res => {
        if (res.success) {
          toast.success('修改成功')
          close()
          refresh()
        }
      })
    } else {
      //新增
      ajax.addGroup({ name: state.desc }).then(res => {
        if (res.success) {
          toast.success('新增成功')
          close()
          refresh()
        }
      })
    }
  }

  return (
    <div className='dict-model'>
      <div className='city-wrap'>
        <div className='title-wrap'>
          <div className='title'>{item ? '编辑' : '新增'}所属小组</div>
          <div className='close' onClick={close}></div>
        </div>
        <div className='content-wrap'>
          <div className='label'>所属小组：</div>
          <Text
            placeholder="请输入所属小组"
            value={state.desc}
            onChange={v => dispatch({ desc: v.trim() })}
          ></Text>
        </div>
        <div className='button-wrap'>
          <div className='confirm' onClick={confirm}>确认</div>
        </div>
      </div>
    </div>
  )
}