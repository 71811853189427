import {useReducer} from 'react'

// useReducer的简化版
function useFastReducer(initialArg) {
  const [state, dispatch] = useReducer((state, action) => {
    return { ...state, ...action }
  }, initialArg)

  return [state, dispatch]
}

export {
  useFastReducer,
}