import { oaService } from './index'

//运营城市
function getCityList(params) {
  return oaService.post('/dict/cityList', params)
}
function addCity(params) {
  return oaService.post('/dict/addCity', params)
}
function editCity(params) {
  return oaService.post('/dict/updCity', params)
}
function deleteCity(id) {
  return oaService.put(`/dict/delCity/${id}`)
}

//运营区域
function getAreaList(params) {
  return oaService.post('/dict/areaList', params)
}
function addArea(params) {
  return oaService.post('/dict/addArea', params)
}
function editArea(params) {
  return oaService.post('/dict/updArea', params)
}
function deleteArea(id) {
  return oaService.put(`/dict/delArea/${id}`)
}

//所属小组
function getGroupList(params) {
  return oaService.post('/dict/groupList', params)
}
function addGroup(params) {
  return oaService.post('/dict/addGroup', params)
}
function editGroup(params) {
  return oaService.post('/dict/updGroup', params)
}
function deleteGroup(id) {
  return oaService.put(`/dict/delGroup/${id}`)
}

//微信账号
function getWXList(params) {
  return oaService.post('/dict/wechatNumberList', params)
}
function addWX(params) {
  return oaService.post('/dict/addWechatNumber', params)
}
function editWX(params) {
  return oaService.post('/dict/updWechatNumber', params)
}
function deleteWX(id) {
  return oaService.put(`/dict/delWechatNumber/${id}`)
}


//字典-行政区省市
function getDistrict() {
  return oaService.get('/dict/getDistrict')
}
function searchDistrict(keyword) {
  return oaService.get('/dict/search?name=' + keyword)
}

//字典-运营城市列表
function getOperateCityList() {
  return oaService.get('/dict/getCity')
}

//字典-运营微信账号
function getWXAccount() {
  return oaService.get('/dict/getWechatNumber')
}

//字典-所属区域
function getOpArea() {
  return oaService.get('/dict/getArea')
}


export default {
  getCityList,
  addCity,
  editCity,
  deleteCity,

  getAreaList,
  addArea,
  editArea,
  deleteArea,

  getGroupList,
  addGroup,
  editGroup,
  deleteGroup,

  getWXList,
  addWX,
  editWX,
  deleteWX,

  getOperateCityList,
  getDistrict,
  searchDistrict,
  getWXAccount,
  getOpArea,
}