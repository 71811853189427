import {useState} from 'react'

export default function Input({type = 'text', value, onKeyDown, placeholder, onChange}) {
  const [focus, setFocus] = useState(false)

  function on() {
    setFocus(true)
  }
  function off() {
    setFocus(false)
  }
  function change(e) {
    onChange(e.target.value.trim())
  }
  return (
    <div className={`com-input ${focus}`}>
      <input
        type={type}
        autoComplete="off"
        className="input"
        placeholder={placeholder}
        value={value}
        onFocus={on}
        onBlur={off}
        onKeyDown={onKeyDown}
        onChange={change}
      />
    </div>
  )
}