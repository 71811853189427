import { useFastReducer } from '../components/hooks'
import showConfirm from '../components/confirm'
import { useEffect } from 'react'
import Breadcrumb from '../common/Breadcrumb'
import ajax from '../ajax/dict'
import { Pagination } from 'antd'
import toast from '../components/toast'
import ChooseArea from '../components/choose-area/ChooseArea'
import './dict.scss'

export default function AreaList() {
  const [state, dispatch] = useFastReducer({
    page: 1,
    size: 15,
    isModel: false,
    list: null,
    areaName: '',
    areaList: [],
  })

  //新增
  function add() {
    dispatch({ isModel: true, title: '新增所属区域', item: null, areaName: '', areaList: [] })
  }
  //编辑
  function edit(item) {
    dispatch({ isModel: true, title: '编辑所属区域', item, areaName: item.areaName, areaList: item.areaList })
  }
  //选择城市
  function changeCity(value) {
    if (state.item) {
      //编辑
      const params = {
        id: state.item.id,
        areaName: value.areaName,
        pcIds: value.areaList.map(item => (item.id || item.pid)).join(','),
      }
      ajax.editArea(params).then(res => {
        if (res.success) {
          closeModel()
          getList()
          toast.success('修改成功')
        }
      })
    } else {
      //新增
      const params = {
        areaName: value.areaName,
        pcIds: value.areaList.map(item => (item.id || item.pid)).join(','),
      }
      ajax.addArea(params).then(res => {
        if (res.success) {
          closeModel()
          toast.success('新增成功')
          if (state.page === 1) {
            getList()
          } else {
            dispatch({ page: 1 })
          }
        }
      })
    }
  }
  //关闭弹窗
  function closeModel() {
    dispatch({ isModel: false })
  }

  //删除
  function delItem(id) {
    showConfirm(
      {
        title: '提示',
        msg: '是否确认删除该所属区域？',
      },
      () => {
        ajax.deleteArea(id).then(res => {
          if (res.success) {
            toast.success('删除成功')
            getList()
          }
        })
      },
    )
  }

  //获取列表
  function getList() {
    const params = {
      pageNum: state.page,
      pageSize: state.size
    }
    ajax.getAreaList(params).then(res => {
      if (res.success) {
        dispatch({
          list: res.data.records,
          current: res.data.current,
          total: res.data.total,
        })
      }
    })
  }

  // 分页切换0
  function pageChange(page) {
    dispatch({ page })
  }

  useEffect(() => {
    getList()
  }, [state.page])

  return (
    <div className="com-content dict-list">
      <div className="com-fix-wrap">
        <div className="flex align between">
          <Breadcrumb path="/dict/area" />
          <div className="plus-button" onClick={add}>新增所属区域</div>
        </div>
        <div className='table-wrap'>
          <div className='table-head'>
            <div className='col-start'>所属区域</div>
            <div className='col-city'>所属省/市</div>
            <div className='col-grow'>创建时间</div>
            <div className='col-end'>操作</div>
          </div>
          <div className='table-content'>
            {state.list && state.list.map((item, index) =>
              <div className='row' key={index}>
                <div className='col-start'>{item.areaName}</div>
                <div className='col-city'>{item.areaList.map(item => item.name).join(' ')}</div>
                <div className='col-grow'>{item.createTime}</div>
                <div className='col-end'>
                  <div className='del-btn' onClick={() => delItem(item.id)}><div className='del-icon' />删除</div>
                  <div className='edit-btn' onClick={() => edit(item)}><div className='edit-icon' />编辑</div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='pagination'>
          <Pagination align="end" showQuickJumper current={state.current} pageSize={state.size} total={state.total} onChange={pageChange} />
        </div>
      </div>
      {state.isModel && <ChooseArea all input value={state.areaList} inputValue={state.areaName} title={state.title} close={closeModel} onChange={changeCity} />}
    </div>
  )
}


